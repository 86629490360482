import { useState } from "react";
import { kebabCase } from "lodash";
import { cn } from "utils/cn";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import TableWrapper from "components/admin/table/TableWrapper";
import useClient from "hooks/useClient";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import IconInformationCircleSolid from "public/images/icons/information-circle_solid.svg";
import type { FieldOption } from "types/model/field-option";
import type { IdsHash } from "types/general";
import FieldOptionAddEdit from "components/admin/FieldOptionAddEdit";
import { XCircleIcon } from "@heroicons/react/20/solid";

interface FieldOptionsProps
  extends UseFieldApiProps<FieldOption[], HTMLElement> {
  fieldOptionsUsedCheck?: IdsHash;
  fieldOptionIdsUsedForHomeTab?: string[];
  isNewField?: boolean;
}

const FieldOptions = (props: UseFieldApiConfig): React.ReactElement => {
  const {
    isNewField,
    fieldOptionsUsedCheck,
    fieldOptionIdsUsedForHomeTab = [],
    label,
    meta: { error, touched },
    input
  }: FieldOptionsProps = useFieldApi(props);
  const [isDirty, setIsDirty] = useState(false);
  const [isAddingNewFieldOption, setIsAddingNewFieldOption] = useState(false);
  const [editingFieldOptionId, setEditingFieldOptionId] =
    useState<string>(null);
  const [newOrEditingFieldOption, setNewOrEditingFieldOption] =
    useState<FieldOption>(null);

  const clientQueryInfo = useClient();

  const handleAddNewFieldOption = (): void => {
    setIsAddingNewFieldOption(true);
  };

  const onConfirmFieldOptionAdd = (): void => {
    const fieldOption: Partial<FieldOption> = {
      _id: `temp_${Math.random().toString(36).substring(7)}`,
      name: newOrEditingFieldOption.name,
      enabled: true
    };

    const updatedFieldOptions = [...input.value, fieldOption];

    input.onChange(updatedFieldOptions);
    setIsDirty(true);

    setIsAddingNewFieldOption(false);
    setNewOrEditingFieldOption(null);
  };

  const onEditFieldOption = (id: string): void => {
    const editingFieldOption = input.value.find(
      fieldOption => fieldOption._id === id
    );
    setNewOrEditingFieldOption(editingFieldOption);
    if (editingFieldOption) {
      setEditingFieldOptionId(editingFieldOption._id);
    }
  };

  const onConfirmFieldOptionEdit = (): void => {
    const editingFieldOptionIndex = input.value.findIndex(
      fieldOption => fieldOption._id === editingFieldOptionId
    );

    const updatedFieldOptionValues = [...input.value];

    updatedFieldOptionValues[editingFieldOptionIndex] = newOrEditingFieldOption;

    input.onChange(updatedFieldOptionValues);
    setIsDirty(true);

    setEditingFieldOptionId(null);
    setNewOrEditingFieldOption(null);
  };

  const onCancelFieldOptionAddEdit = (): void => {
    setIsAddingNewFieldOption(false);
    setEditingFieldOptionId(null);
    setNewOrEditingFieldOption(null);
  };

  const onUpdateFieldOptionStatus = (id: string): void => {
    if (fieldOptionIdsUsedForHomeTab.includes(id)) {
      window.alert(
        "Sorry, this Field option cannot be disabled as it is used for a Home page tab. Please update or delete the tab first."
      );
      return;
    }

    const fieldOptionToUpdateStatusIndex = input.value.findIndex(
      fieldOption => fieldOption._id === id
    );

    const updatedFieldOptionValues = [...input.value];

    updatedFieldOptionValues[fieldOptionToUpdateStatusIndex].enabled =
      !updatedFieldOptionValues[fieldOptionToUpdateStatusIndex].enabled;

    input.onChange(updatedFieldOptionValues);
    setIsDirty(true);
  };

  const onDeleteFieldOption = (id: string): void => {
    if (fieldOptionIdsUsedForHomeTab.includes(id)) {
      window.alert(
        "Sorry, this Field option cannot be deleted as it is used for a Home page tab. Please update or delete the tab first."
      );
      return;
    }

    if (fieldOptionsUsedCheck[id]) {
      window.alert(
        "Sorry, this Field option cannot be deleted as there are already data for it. Please disable it instead."
      );
      return;
    }
    const updatedFields = input.value.filter(field => field._id !== id);
    input.onChange(updatedFields);
    setIsDirty(true);
  };

  return clientQueryInfo.data ? (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {isDirty && !isNewField && (
        <div className="mb-5 rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <IconInformationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Changes will be applied on save.
              </p>
            </div>
          </div>
        </div>
      )}

      {touched && error && (
        <>
          <div className="mb-5 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p
                  className="text-sm text-red-700"
                  data-cy={`error-${kebabCase(label)}`}
                >
                  {error}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {input.value.length > 0 && (
        <TableWrapper shadow={false}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Name
                </th>
                <th className="bg-gray-50 px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {input.value.map((fieldOption, index) => (
                <tr className="bg-white" key={index}>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm font-medium text-gray-900",
                      !fieldOption.enabled && "text-opacity-50"
                    )}
                  >
                    {fieldOption.name}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium",
                      !fieldOption.enabled && "text-opacity-50"
                    )}
                  >
                    <a
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={(): void => onEditFieldOption(fieldOption._id)}
                    >
                      Edit
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={(): void =>
                        onUpdateFieldOptionStatus(fieldOption._id)
                      }
                    >
                      {fieldOption.enabled ? "Disable" : "Enable"}
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={(): void => onDeleteFieldOption(fieldOption._id)}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      <div className={cn(input.value.length > 0 && "mt-4")}>
        <span className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleAddNewFieldOption}
          >
            <IconPlusCircle
              width={24}
              height={24}
              className="-ml-1 mr-2 h-5 w-5"
            />
            Add new option
          </button>
        </span>
      </div>

      <FieldOptionAddEdit
        fieldOption={newOrEditingFieldOption}
        setFieldOptionData={setNewOrEditingFieldOption}
        isOpen={isAddingNewFieldOption || Boolean(editingFieldOptionId)}
        onConfirm={
          isAddingNewFieldOption
            ? onConfirmFieldOptionAdd
            : onConfirmFieldOptionEdit
        }
        onCancel={onCancelFieldOptionAddEdit}
      />
    </div>
  ) : null;
};

export default FieldOptions;
