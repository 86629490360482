import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { SubmitButton } from "components/form/SubmitButton";
import { FormError } from "components/FormError";
import { Fragment, useRef, useState } from "react";
import type { CcvProvider } from "types/model/payment";

interface CcvProviderAddEditProps {
  ccvProvider: CcvProvider;
  isOpen: boolean;
  setCcvProviderData: (ccvProvider: CcvProvider) => void;
  onConfirm: () => void;
  onCancel: () => void;
}

const CcvProviderAddEdit = ({
  ccvProvider,
  isOpen,
  setCcvProviderData,
  onConfirm,
  onCancel
}: CcvProviderAddEditProps): React.ReactElement => {
  const nameInputRef = useRef();
  const [isFormValidationError, setIsFormValidationError] = useState(false);

  const handleSetValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = e.target;
    setCcvProviderData({
      ...ccvProvider,
      [name]: value
    });
  };

  const handleOnConfirm = () => {
    if (!ccvProvider?.name) {
      setIsFormValidationError(true);
      return;
    }
    onConfirm();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={nameInputRef}
        open={isOpen}
        onClose={onCancel}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle">
              {/* Added `min-w-full sm:min-w-min` so it goes full width on mobile */}
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                    {/* Added `w-full`to the above */}
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {ccvProvider?._id
                        ? "Edit Childcare Voucher Provider"
                        : "Add new Childcare Voucher Provider"}
                    </Dialog.Title>
                    {isFormValidationError && !ccvProvider?.name && (
                      <FormError className="mt-4">
                        Please supply a name for the Childcare Voucher Provider.
                      </FormError>
                    )}
                    <div className="mb-4 mt-6">
                      <div>
                        <label
                          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                          htmlFor="name"
                        >
                          Provider name
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm sm:max-w-xs">
                          <input
                            ref={nameInputRef}
                            id="ccvProviderName"
                            name="name"
                            type="text"
                            required
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={ccvProvider?.name || ""}
                            onChange={handleSetValue}
                          />
                        </div>
                      </div>
                      <div className="mt-5">
                        <label
                          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                          htmlFor="ccvProviderPaymentInstructions"
                        >
                          Payment instructions
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm sm:max-w-xs">
                          <input
                            id="ccvProviderPaymentInstructions"
                            name="paymentInstructions"
                            type="text"
                            placeholder="Please search using reference XXXXX"
                            required
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={ccvProvider?.paymentInstructions || ""}
                            onChange={handleSetValue}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <SubmitButton
                  buttonType="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleOnConfirm}
                >
                  OK
                </SubmitButton>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CcvProviderAddEdit;
