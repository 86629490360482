import { cn } from "utils/cn";
import { sortAddons } from "helpers/activity";
import { formatDate } from "helpers/date";
import type { AddOnWithActivity } from "types/model/activity";
import type { Client } from "types/model/client";

interface SelectedAddOnsProps {
  addOns: AddOnWithActivity[];
  client: Client;
  customClassName?: string;
}

const SelectedAddOns = ({
  addOns,
  client,
  customClassName
}: SelectedAddOnsProps): React.ReactElement => {
  return (
    <div>
      {addOns
        .sort(sortAddons)
        .reduce((acc, addOn) => {
          const existingAddOnItem = acc.find(item => item._id === addOn._id);
          if (existingAddOnItem && addOn.activity?.date) {
            existingAddOnItem.dates.push(addOn.activity.date);
          } else if (!existingAddOnItem) {
            acc.push({
              ...addOn,
              ...(addOn.activity?.date && {
                dates: [addOn.activity?.date]
              })
            });
          }
          return acc;
        }, [])
        .map((addOn, index) => (
          <div
            key={index}
            className={cn(customClassName, "text-sm font-normal text-gray-500")}
            data-cy="selected-add-on-item"
          >
            + {addOn.name}
            {addOn.dates &&
              addOn.dates.map((dateItem, index) => (
                <span key={index} className="ml-1">
                  {formatDate(
                    dateItem.start,
                    client.dateFormatShort,
                    client.timeZone
                  )}
                </span>
              ))}
          </div>
        ))}
    </div>
  );
};

export default SelectedAddOns;
