// Icons exported from https://github.com/aaronfagan/svg-credit-card-payment-icons/tree/master/flat-rounded

import { AfterpayIcon } from "components/icons/AfterpayIcon";
import { ApplePayIcon } from "components/icons/ApplePayIcon";
import { GooglePayIcon } from "components/icons/GooglePayIcon";
import { KlarnaIcon } from "components/icons/KlarnaIcon";
import { LinkIcon } from "components/icons/LinkIcon";
import { RevolutPayIcon } from "components/icons/RevolutPayIcon";
import AmexIcon from "public/images/icons/card/amex.svg";
import DinersIcon from "public/images/icons/card/diners.svg";
import DiscoverIcon from "public/images/icons/card/discover.svg";
import GenericIcon from "public/images/icons/card/generic.svg";
import JcbIcon from "public/images/icons/card/jcb.svg";
import LinkIconSvg from "public/images/icons/card/link.svg";
import MastercardIcon from "public/images/icons/card/mastercard.svg";
import UnionPayIcon from "public/images/icons/card/unionpay.svg";
import VisaIcon from "public/images/icons/card/visa.svg";
import { cn } from "utils/cn";

const cardIcons = {
  amex: AmexIcon,
  diners: DinersIcon,
  discover: DiscoverIcon,
  jcb: JcbIcon,
  mastercard: MastercardIcon,
  unionpay: UnionPayIcon,
  visa: VisaIcon,
  link: LinkIconSvg,
  link2: LinkIcon,
  unknown: GenericIcon,
  applePay: ApplePayIcon,
  googlePay: GooglePayIcon,
  revolutPay: RevolutPayIcon,
  klarna: KlarnaIcon,
  afterpay: AfterpayIcon
};

type Props = {
  /**
   * `amex`, `diners`, `discover`, `jcb`, `mastercard`, `unionpay`, `visa`, `link` or `unknown`.
   */
  brand: string;
  className?: string;
};

const CardIcon = ({ brand, className }: Props) => {
  const baseStyle = "h-4 w-auto";

  return (
    cardIcons?.[brand]?.({ className: cn(baseStyle, className) }) || (
      <GenericIcon className={cn(baseStyle, className)} />
    )
  );

  // return (
  //   <>
  //     {brand === "amex" ? (
  //       <AmexIcon className={cn(baseStyle, className)} />
  //     ) : brand === "diners" ? (
  //       <DinersIcon className={cn(baseStyle, className)} />
  //     ) : brand === "discover" ? (
  //       <DiscoverIcon className={cn(baseStyle, className)} />
  //     ) : brand === "jcb" ? (
  //       <JcbIcon className={cn(baseStyle, className)} />
  //     ) : brand === "mastercard" ? (
  //       <MastercardIcon className={cn(baseStyle, className)} />
  //     ) : brand === "unionpay" ? (
  //       <UnionPayIcon className={cn(baseStyle, className)} />
  //     ) : brand === "visa" ? (
  //       <VisaIcon className={cn(baseStyle, className)} />
  //     ) : brand === "link" ? (
  //       <LinkIcon className={cn(baseStyle, className)} />
  //     ) : (
  //       <GenericIcon className={cn(baseStyle, className)} />
  //     )}
  //   </>
  // );
};

export default CardIcon;
