import { cn } from "utils/cn";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { FormTemplateType } from "types/form";
import type { UserReduced } from "types/model/user";

interface UserDisplayProps extends UseFieldApiProps<string, HTMLElement> {
  user?: UserReduced;
  formTemplate?: FormTemplateType;
}

const UserDisplay = (props: UseFieldApiConfig): React.ReactElement => {
  const {
    label,
    input,
    index,
    user,
    formTemplate = FormTemplateType.Default
  }: UserDisplayProps = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  return (
    <div
      className={cn(
        isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        isSeamlessFormTemplate && !isFirstItem && "mt-5"
      )}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
      </label>
      <div className="text-sm text-gray-700">{user.fullName}</div>
    </div>
  );
};

export default UserDisplay;
