import { Alert } from "components/Alert";
import CardIcon from "components/site/CardIcon";
import { Skeleton } from "components/ui/skeleton";
import { StripePaymentMethod } from "features/stripe/components/StripePaymentMethod";
import { useStripePaymentMethodConfiguration } from "features/stripe/hooks/useStripePaymentMethodConfiguration";
import { StripePaymentMethods } from "features/stripe/types";
import { Client } from "types/model/client";

const klarnaSupportedCountries = [
  "US",
  "CA",
  "AT",
  "BE",
  "DE",
  "NL",
  "CH",
  "DK",
  "FI",
  "NO",
  "SE",
  "CZ",
  "FR",
  "GR",
  "IE",
  "IT",
  "PL",
  "PT",
  "RO",
  "ES",
  "GB",
  "AU",
  "NZ"
];

const afterPaySupportedCountries = ["AU", "CA", "NZ", "GB"];

const revolutSupportedCountries = ["GB", "IE", "NO", "ES", "CY"];

export const StripePaymentConfiguration = ({ client }: { client: Client }) => {
  const {
    data: paymentMethodConfiguration,
    isLoading,
    error
  } = useStripePaymentMethodConfiguration();

  if (isLoading || !paymentMethodConfiguration) {
    return <Skeleton className="mt-4 h-[300px]" />;
  }

  if (error?.message) {
    return (
      <Alert variant="red" className="mt-4 w-full">
        {error.message}
      </Alert>
    );
  }

  const card = paymentMethodConfiguration[StripePaymentMethods.Card];
  paymentMethodConfiguration[StripePaymentMethods.GooglePay];
  const revolut = paymentMethodConfiguration[StripePaymentMethods.RevolutPay];
  const klarna = paymentMethodConfiguration[StripePaymentMethods.Klarna];
  const clearpay =
    paymentMethodConfiguration[StripePaymentMethods.AfterpayClearpay];

  // "pay_by_bank" is not a valid key in the object.
  // const bank =
  //   paymentMethodConfiguration[StripePaymentMethods.PayByBank];

  return (
    <section className="mt-6 flex flex-col gap-2">
      <h3 className="mb-1 text-sm font-medium text-gray-900">
        Stripe Payment Methods
      </h3>
      <div className="flex flex-col gap-4">
        <StripePaymentMethod
          paymentMethodConfigId={paymentMethodConfiguration.id}
          paymentMethod={StripePaymentMethods.Card}
          displayName="Card"
          enabled={card?.display_preference.value === "on" ? true : false}
          disabled
          icon={
            <CardIcon
              brand="unknown"
              className="grid h-5 w-[31px] place-content-center rounded-[2px] border border-[#000C9E] bg-[#000C9E]"
            />
          }
        />
        {revolutSupportedCountries.includes(client.country) && (
          <StripePaymentMethod
            paymentMethodConfigId={paymentMethodConfiguration.id}
            paymentMethod={StripePaymentMethods.RevolutPay}
            displayName="Revolut Pay"
            enabled={revolut?.display_preference.value === "on" ? true : false}
            icon={<CardIcon brand="revolutPay" />}
          />
        )}
        {klarnaSupportedCountries.includes(client.country) && (
          <StripePaymentMethod
            paymentMethodConfigId={paymentMethodConfiguration.id}
            paymentMethod={StripePaymentMethods.Klarna}
            displayName="Klarna"
            enabled={klarna?.display_preference.value === "on" ? true : false}
            icon={<CardIcon brand="klarna" />}
          />
        )}
        {/* {afterPaySupportedCountries.includes(client.country) && (
          <StripePaymentMethod
            paymentMethodConfigId={paymentMethodConfiguration.id}
            paymentMethod={StripePaymentMethods.AfterpayClearpay}
            displayName="Afterpay / Clearpay"
            enabled={clearpay?.display_preference.value === "on" ? true : false}
            icon={<CardIcon brand="afterpay" />}
          />
        )} */}
      </div>
      {/* link to stripe for fees info */}
      <p className="mt-4 text-sm">
        For details on pricing for each payment method, visit{" "}
        <a
          href="https://stripe.com/pricing/local-payment-methods"
          target="_blank"
          rel="noreferrer"
          className="text-sm font-medium text-indigo-600 group-hover:text-indigo-900 group-hover:outline-none"
        >
          Stripe&apos;s Pricing Page
        </a>
        .
      </p>
      <p className="mt-2 text-sm">
        Additional payment methods can be configured in your Stripe dashboard.
      </p>
    </section>
  );
};
