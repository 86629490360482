import { useEffect, useState } from "react";

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the width
    const updateWidth = () => setWidth(window.innerWidth);

    let observer;
    if (typeof ResizeObserver === "function") {
      // Create a ResizeObserver if it is supported
      observer = new ResizeObserver(updateWidth);
      observer.observe(document.body);
    } else {
      // Use the resize event listener if ResizeObserver is not supported
      window.addEventListener("resize", updateWidth);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      } else {
        window.removeEventListener("resize", updateWidth);
      }
    };
  }, []);

  return width;
};

export default useWindowWidth;
