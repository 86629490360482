import { useCallback, useState } from "react";

interface Position {
  top: number;
  bottom: number;
  left: number;
  right: number;
  height: number;
  width: number;
}

const useRelativeElementPosition = (
  parentSelector: string,
  childSelector: string
): [Position, () => void] => {
  const [position, setPosition] = useState<Position>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: 0,
    width: 0
  });

  const updatePosition = useCallback(() => {
    const parent = document.querySelector(parentSelector) as HTMLElement;
    const child = document.querySelector(childSelector) as HTMLElement;
    if (parent && child) {
      const parentRect = parent.getBoundingClientRect();
      const childRect = child.getBoundingClientRect();

      // Calculate relative positions and dimensions immediately
      setPosition({
        top: childRect.top - parentRect.top,
        bottom: parentRect.bottom - childRect.bottom,
        left: childRect.left - parentRect.left,
        right: parentRect.right - childRect.right,
        height: childRect.height,
        width: childRect.width
      });
    }
  }, [parentSelector, childSelector]);

  return [position, updatePosition];
};

export default useRelativeElementPosition;
