import { checkAgeEligibility } from "helpers/activity";
import type { Activity } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";

type Args = {
  userAttendees: Attendee[];
  currentAttendee: string;
  activityGroup: ActivityGroup;
  sessions: Activity<string>[];
};

export function useCheckAgeEligibility({
  userAttendees,
  currentAttendee,
  activityGroup,
  sessions
}: Args) {
  const attendee = userAttendees.find(
    attendee => attendee._id === currentAttendee
  );

  const restrictions =
    attendee && checkAgeEligibility(attendee, activityGroup, sessions);

  const hasBlock = restrictions?.shouldBlockAttendeeIfOutsideAgeRestriction;

  return { restrictions, hasBlock };
}
