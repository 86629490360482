/**
 * Convert minutes to hours and minutes. For example, passing 90 will return
 * { hours: "1", minutes: "30" }. This is useful for displaying the time in
 * a more human-readable format.
 */
export function convertToHours(minutes: number): {
  hours: string;
  minutes: string;
} {
  return {
    hours: String(Math.floor(minutes / 60)),
    minutes: String(minutes % 60)
  };
}
