import { XCircleIcon } from "@heroicons/react/20/solid";
import type { ReactNode } from "react";
import { cn } from "utils/cn";

type Props = {
  children: ReactNode;
  className?: string;
};

export const FormError = ({ children, className }: Props) => (
  <div
    className={cn("mb-4 rounded-md bg-red-50 p-4", className)}
    data-cy="form-error"
  >
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <div className="text-sm text-red-700">{children}</div>
      </div>
    </div>
  </div>
);
