import { cn } from "utils/cn";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import FieldArrayFF from "@data-driven-forms/react-form-renderer/field-array";
import IconPlusCircle from "public/images/icons/plus-circle.svg";

const ArrayItem = ({
  index,
  remove,
  fields,
  name,
  // removeLabel,
  // ArrayItemProps,
  // RemoveButtonProps,
  disabledRemove,
  itemLabel
}): React.ReactElement => {
  const formOptions = useFormApi();

  const editedFields = fields.map(field => ({
    ...field,
    ...(field.name ? { name: `${name}.${field.name}` } : { name })
  }));

  return (
    <div className={cn(index !== 0 && "mt-5 border-t border-gray-200 pt-5")}>
      <label className="block pb-2 text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
        {itemLabel ? itemLabel : "Item"} {index + 1} (
        <a
          onClick={remove}
          className={cn(
            "cursor-pointer font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline focus:outline-none",
            disabledRemove && "pointer-events-none"
          )}
        >
          Remove
        </a>
        )
      </label>
      {formOptions.renderForm(editedFields)}
    </div>
  );
};

const FieldArray = (props): React.ReactElement => {
  const {
    defaultItem,
    fields,
    arrayValidator,
    buttonLabels,
    meta: { error, touched },
    // AddContainerProps,
    // RemoveButtonProps,
    // ArrayItemProps,
    FieldArrayProps,
    noItemsMessage,
    maxItems,
    // minItems,
    itemLabel,
    index,
    isSubFormSubmitted,
    isDisabled = false
  } = useFieldApi(props);

  const combinedLabels = {
    add: `Add ${itemLabel ? itemLabel.toLowerCase() : "item"}`,
    remove: "Remove",
    ...buttonLabels
  };

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;
  const errorText = typeof error === "string" ? error : "";

  return (
    <div className={cn(index !== 0 && "mt-5 border-t border-gray-200 pt-5")}>
      <FieldArrayFF name={props.name} validate={arrayValidator}>
        {(fieldArrayProps): React.ReactElement => (
          <div {...FieldArrayProps}>
            {fieldArrayProps.fields.length === 0 && noItemsMessage}
            {fieldArrayProps.fields.map((name, index) => (
              <ArrayItem
                key={index}
                remove={(): void => fieldArrayProps.fields.remove(index)}
                name={name}
                index={index}
                fields={fields}
                // removeLabel={combinedLabels.remove}
                // ArrayItemProps={ArrayItemProps}
                // RemoveButtonProps={RemoveButtonProps}
                itemLabel={itemLabel}
                disabledRemove={isDisabled}
              />
            ))}
            <div className="mt-4">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  onClick={(): void => fieldArrayProps.fields.push(defaultItem)}
                  disabled={
                    isDisabled || fieldArrayProps.fields.length >= maxItems
                  }
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <IconPlusCircle
                    width={24}
                    height={24}
                    className="-ml-1 mr-2 h-5 w-5"
                  />
                  {combinedLabels.add}
                </button>
              </span>
            </div>
          </div>
        )}
      </FieldArrayFF>
      {touchedOrSubFormSubmitted && errorText && (
        <p className="mt-2 text-sm text-red-600">{errorText}</p>
      )}
    </div>
  );
};

export default FieldArray;
