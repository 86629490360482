import type { AxiosError } from "axios";
import axios from "axios";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type Stripe from "stripe";
import type { ErrorResponse } from "types";

export function useStripePaymentMethodConfiguration(): QueryResult<
  Stripe.PaymentMethodConfiguration,
  AxiosError<ErrorResponse>
> {
  return useQuery(
    ["paymentMethodConfigurations"],
    async () => {
      const response = await axios.get<Stripe.PaymentMethodConfiguration>(
        "/api/stripe/payment-method-configurations"
      );

      return response.data;
    },
    {
      enabled: true
    }
  );
}
