import type { ActivitySessionsBookedForAttendee } from "features/bookings/types";

export const getIsSessionBookedForAttendee = (
  activitySessionsBookedByUser: ActivitySessionsBookedForAttendee[],
  activityId: string,
  attendeeId: string,
  isServerSide = false
) => {
  const sessionsAlreadyBookedForAttendee = activitySessionsBookedByUser.find(
    item =>
      isServerSide ? item._id.equals(attendeeId) : item._id === attendeeId
  );

  if (!sessionsAlreadyBookedForAttendee) {
    return false;
  }

  // On the server side, convert the ObjectID entries to strings before comparison
  const sessionsAlreadyBookedForAttendeeAsStrings = isServerSide
    ? sessionsAlreadyBookedForAttendee.activities.map(_id => _id.toString())
    : sessionsAlreadyBookedForAttendee.activities;

  return sessionsAlreadyBookedForAttendeeAsStrings.includes(activityId);
};
