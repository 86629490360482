import { isBefore } from "date-fns";
import { formatDate } from "helpers/date";
import { omit } from "lodash";
import { TicketType } from "types/model/activity";
import type { Booking } from "types/model/booking";
import type { Client } from "types/model/client";
import type { LineItem } from "types/model/line-item";
import { LineItemType } from "types/model/line-item";
import type {
  PassDebitItemToAdjust,
  PassDebitRefundCreditAmount,
  SessionPass,
  SessionPassCreditsThatCanBeApplied,
  SessionPassExpiresAfter,
  SessionPassFormData,
  SessionPassTransaction,
  SessionPassTransactionWithCreditUsage,
  TotalForSessionItem
} from "types/model/session-pass";
import {
  PassCreditUsageOption,
  SessionPassCreditTransactionStatus
} from "types/model/session-pass";
import type { TableColumn, TableData } from "types/model/table";

interface GetSessionPassDataForSubmissionData {
  formData: SessionPassFormData;
}

export const getSessionPassFormDataForSubmission = ({
  formData
}: GetSessionPassDataForSubmissionData): SessionPassFormData<string[]> => {
  const formDataToSubmit: SessionPassFormData<string[]> = omit(formData, [
    "activityGroups"
  ]);

  if (
    formDataToSubmit.passCreditUsageOption ===
    PassCreditUsageOption.SelectedActivityGroups
  ) {
    const activityGroups = formData.activityGroups || [];
    formDataToSubmit.activityGroups = activityGroups.map(
      activityGroup => activityGroup._id
    );
  }

  return formDataToSubmit;
};

export const getExpiresTextValueFromInput = (
  value: SessionPassExpiresAfter
): string => {
  if (value?.amount > 0) {
    const isPlural = value.amount > 1;
    return `${value.amount} ${isPlural ? value.unit : value.unit.slice(0, -1)}`;
  }
  return "Not set";
};

export const sessionPassBookingPopulation = [
  "sessionPassPurchaseOption",
  "sessionPass"
];

interface GetUserSessionPassTransactionsTableData {
  data: SessionPassTransactionWithCreditUsage<SessionPass, string>[];
  client: Client;
}

export const getUserSessionPassTransactionsTableData = ({
  data,
  client
}: GetUserSessionPassTransactionsTableData): TableData => {
  const formattedData = data.map(item => {
    return {
      created: formatDate(item.created, "d MMM yyyy, h.mmaaaa", client.timeZone)
        .replace("a.m.", "am")
        .replace("p.m.", "pm"),
      sessionPass: `${item.sessionPass.name} ${
        item.sessionPass.deleted ? " (Deleted)" : ""
      }`,
      creditsUsed: {
        type: "html",
        value: item.debitTransactions?.length
          ? `<a class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline cursor-pointer">${Math.abs(
              item.creditUsage
            )} / ${item.amount}</a>`
          : `${Math.abs(item.creditUsage)} / ${item.amount}`,
        clickAction: "showDebitTransactionsModal"
      },
      expiry: item.expires
        ? formatDate(item.expires, "d MMM yyyy", client.timeZone)
            .replace("a.m.", "am")
            .replace("p.m.", "pm")
        : "No expiry",
      status: getReadableSessionPassStatus(item.passStatus),
      item,
      debitTransactions: item.debitTransactions,
      creditUsage: Math.abs(item.creditUsage),
      amount: item.amount,
      deductCredit: {
        type: "html",
        value:
          item.passStatus == SessionPassCreditTransactionStatus.Active
            ? `<a class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline cursor-pointer">Deduct credit</a>`
            : "",
        clickAction: "showDeductCreditModal"
      },
      enabled: true // prevent lower opacity for disabled rows
    };
  });

  return formattedData;
};

export const getUserSessionPassTransactionsColumnHeaders =
  (): TableColumn[] => {
    const columnHeaders = [
      {
        Header: "Created",
        accessor: "created"
      },

      {
        Header: "Session pass",
        accessor: "sessionPass"
      },
      {
        Header: "Credits used",
        accessor: "creditsUsed"
      },
      {
        Header: "Expiry",
        accessor: "expiry"
      },
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "",
        accessor: "deductCredit"
      }
    ];

    return columnHeaders;
  };

export const getSessionPassStatus = (
  sessionPassTransaction: SessionPassTransaction<
    SessionPass,
    string,
    string,
    SessionPassTransaction
  >
) => {
  const creditUsage = (sessionPassTransaction.debitTransactions || []).reduce(
    (acc, item) => (acc += item.amount),
    0
  );

  if (sessionPassTransaction.sessionPass.deleted) {
    return SessionPassCreditTransactionStatus.PassDeleted;
  } else if (sessionPassTransaction.amount <= Math.abs(creditUsage)) {
    return SessionPassCreditTransactionStatus.FullyUsed;
  } else if (isBefore(new Date(sessionPassTransaction.expires), new Date())) {
    return SessionPassCreditTransactionStatus.Expired;
  }

  return SessionPassCreditTransactionStatus.Active;
};

export const getReadableSessionPassStatus = (
  passStatus: SessionPassCreditTransactionStatus
) => {
  if (passStatus === SessionPassCreditTransactionStatus.Active) {
    return "Active";
  } else if (passStatus === SessionPassCreditTransactionStatus.Expired) {
    return "Expired";
  } else if (passStatus === SessionPassCreditTransactionStatus.FullyUsed) {
    return "Fully used";
  } else if (passStatus === SessionPassCreditTransactionStatus.PassDeleted) {
    return "Pass deleted";
  }
  return passStatus;
};

export const getTotalForSessionItemsFromActivityLineItems = (
  activityLineItemsCreditUsedFor: LineItem[]
): TotalForSessionItem[] => {
  const totalForSessionItems = activityLineItemsCreditUsedFor
    .reduce((acc: TotalForSessionItem[], item) => {
      const totalForEachSessionInLineItem = item.activities.map(activity => {
        if (!item.ticket.type) {
          throw new Error("No ticket type found for line item");
        }
        return {
          lineItemId: item._id,
          activityId: activity._id,
          total:
            item.ticket.type === TicketType.All
              ? item.total / item.activities.length
              : item.total
        };
      });
      const updated = [...acc, ...totalForEachSessionInLineItem];
      return updated;
    }, [])
    .sort((a, b) => (a.total < b.total ? 1 : -1));

  return totalForSessionItems;
};

export const getDebitAmountForSessionPass = (
  creditUsage: number,
  totalForSessionItems: TotalForSessionItem[]
): number => {
  const debitAmountForSessionPass = totalForSessionItems
    .sort((a, b) => (a.total < b.total ? 1 : -1))
    .reduce(
      (acc, item) => {
        if (acc.creditUsageCounter > 0) {
          acc.creditUsageCounter--;
          acc.amount += item.total;
        }
        return acc;
      },
      { amount: 0, creditUsageCounter: creditUsage }
    );

  return -Math.round(debitAmountForSessionPass.amount);
};

interface GetPassDebitLineItemAdjustmentsParams {
  booking: Booking;
  passDebitLineItems: LineItem[];
  lineItemsToCancelIds: string[];
  passDebitRefundCreditAmounts: PassDebitRefundCreditAmount[];
}

export const getPassDebitLineItemAdjustments = ({
  booking,
  passDebitLineItems,
  lineItemsToCancelIds: lineItemsToCancel_IDs,
  passDebitRefundCreditAmounts
}: GetPassDebitLineItemAdjustmentsParams): PassDebitItemToAdjust[] => {
  const adjustments = passDebitLineItems.reduce(
    (acc: PassDebitItemToAdjust[], passDebitLineItem) => {
      const lineItemsToCancelIds = lineItemsToCancel_IDs.map(
        lineItemsToCancelID => lineItemsToCancelID.toString()
      );

      const passDebitRefundCreditAmount =
        passDebitRefundCreditAmounts.find(item =>
          passDebitLineItem._id.equals(item.passDebitLineItemId)
        )?.creditAmount || 0;

      const creditUsage = passDebitLineItem.passDebitData?.creditUsage || 0;
      const newCreditUsage = creditUsage - passDebitRefundCreditAmount;

      const allLineItemsPossiblyPaidWithPassCredit = booking.lineItems.filter(
        lineItem =>
          lineItem.type === LineItemType.Activity &&
          !lineItem.cancelled &&
          passDebitLineItem.passDebitData?.activityLineItemsCreditUsedFor.includes(
            lineItem._id
          )
      );

      const lineItemsPossiblyPaidWithPassCreditBeingCancelled =
        allLineItemsPossiblyPaidWithPassCredit.filter(lineItem =>
          lineItemsToCancelIds.includes(lineItem._id.toString())
        );

      const lineItemsPossiblyPaidWithPassCreditNotBeingCancelled =
        allLineItemsPossiblyPaidWithPassCredit.filter(
          lineItem => !lineItemsToCancelIds.includes(lineItem._id.toString())
        );

      const originalTotalsForSessionItems =
        getTotalForSessionItemsFromActivityLineItems(
          allLineItemsPossiblyPaidWithPassCredit
        );

      const totalsForSessionItemsBeingCancelledIssuingPassDebitRefundFor =
        getTotalForSessionItemsFromActivityLineItems(
          lineItemsPossiblyPaidWithPassCreditBeingCancelled
        )
          .sort((a, b) => (a.total < b.total ? 1 : -1))
          .slice(0, passDebitRefundCreditAmount);

      const sessionItemsBeingCancelledIssuingPassDebitRefundForTotal =
        totalsForSessionItemsBeingCancelledIssuingPassDebitRefundFor.reduce(
          (acc, item) => (acc += item.total),
          0
        );

      const newTotalsForSessionItems =
        getTotalForSessionItemsFromActivityLineItems(
          lineItemsPossiblyPaidWithPassCreditNotBeingCancelled
        );

      const originalDebitAmount =
        getDebitAmountForSessionPass(
          creditUsage,
          originalTotalsForSessionItems
        ) + sessionItemsBeingCancelledIssuingPassDebitRefundForTotal;

      const newDebitAmountForUpdatedCreditUsage = getDebitAmountForSessionPass(
        newCreditUsage,
        newTotalsForSessionItems
      );

      acc.push({
        lineItem: passDebitLineItem,
        adjustmentAmount:
          originalDebitAmount - newDebitAmountForUpdatedCreditUsage,
        newTotal: newDebitAmountForUpdatedCreditUsage,
        newCreditUsage
      });

      return acc;
    },
    []
  );

  return adjustments;
};

export const getMergedSessionPassCreditAmountArrays = (
  bookingExistingSessionPassCreditAmounts: SessionPassCreditsThatCanBeApplied[],
  sessionPassCreditsThatCanBeApplied: SessionPassCreditsThatCanBeApplied[]
): SessionPassCreditsThatCanBeApplied[] => {
  const merged: Record<string, SessionPassCreditsThatCanBeApplied> = {};

  const addOrUpdate = (item: SessionPassCreditsThatCanBeApplied) => {
    const id = item.sessionPass._id;

    if (merged[id]) {
      merged[id].creditAmount += item.creditAmount;
    } else {
      merged[id] = { ...item };
    }
  };

  bookingExistingSessionPassCreditAmounts.forEach(addOrUpdate);
  sessionPassCreditsThatCanBeApplied.forEach(addOrUpdate);

  return Object.values(merged);
};
