import type {
  AgeRestrictionAgeInput,
  BookingClosingTimeInput,
  BookingOpenDateInput
} from "types/model/activity-group";
import type {
  ActivitiesCalendarConfig,
  ActivitySalesReportConfig,
  BookingsExportBookingInfoConfig,
  LineItemExportBookingInfoConfig,
  RegisterConfig,
  SessionsBookedReportExportConfig
} from "types/model/client";

// TODO: Combine with types/model/form
export interface FormDataItem {
  [key: string]:
    | string
    | number
    | boolean
    | Date
    | RegisterConfig
    | ActivitySalesReportConfig
    | SessionsBookedReportExportConfig
    | ActivitiesCalendarConfig
    | BookingsExportBookingInfoConfig
    | LineItemExportBookingInfoConfig
    | BookingOpenDateInput
    | BookingClosingTimeInput
    | AgeRestrictionAgeInput
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any[];
}

export interface SelectOption {
  value: string;
  label: string;
  description?: string;
}

export enum FormTemplateType {
  Default = "default",
  Seamless = "seamless"
}

export enum SubmitVariant {
  Default = "default",
  FullWidth = "fullWidth"
}

export type Duration = {
  hours: string;
  minutes: string;
};
