import axios from "axios";
import type { UpdateStripePaymentMethodConfiguration } from "features/stripe/types";
import { queryCache, useMutation } from "react-query";
import { getErrorMessage } from "utils/getErrorMessage";

export function useUpdateStripePaymentMethodConfiguration() {
  return useMutation(
    async (data: UpdateStripePaymentMethodConfiguration) => {
      try {
        return await axios.put(
          "/api/stripe/payment-method-configurations",
          data
        );
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error =>
              `Failed to update Stripe payment method: ${error.message}`,
            otherErrorMessage: "Failed to update Stripe payment method."
          })
        );
      }
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("paymentMethodConfigurations");
      }
    }
  );
}
