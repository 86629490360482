import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

const CheckboxMultipleCircles = (props): React.ReactElement => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    inModal,
    options,
    arrayField,
    isSubFormSubmitted,
    formTemplate = FormTemplateType.Default
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  const handleChange = event => {
    const target = event.target;
    const updatedValue = target.checked
      ? [...input.value, target.id]
      : input.value.filter(item => item !== target.id);
    input.onChange(updatedValue);
  };

  return options.length ? (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
        {label}
        {isRequired && "*"}
      </label>
      <div
        className={cn(
          isDefaultFormTemplate && "mt-1 sm:col-span-2 sm:max-w-sm",
          isSeamlessFormTemplate && "mt-3"
        )}
      >
        <div className="grid grid-cols-3 gap-3 sm:grid-cols-7">
          {options.map(option => (
            <label
              key={option.value}
              className={cn(
                "flex cursor-pointer items-center justify-center rounded-md border px-2 py-2 text-sm font-medium uppercase",
                !input.value.includes(option.value) &&
                  "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                input.value.includes(option.value) &&
                  "border-transparent bg-indigo-600 text-white hover:bg-indigo-700"
              )}
            >
              <span className="memory-option-0-label">{option.label}</span>
              <input
                id={option.value}
                name={option.value}
                type="checkbox"
                className="sr-only"
                checked={input.value.includes(option.value)}
                onChange={handleChange}
                data-cy={`form-field-${kebabCase(option.label)}`}
              />
            </label>
          ))}
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p className="mt-3 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  ) : null;
};

export default CheckboxMultipleCircles;
