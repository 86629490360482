import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { UpdateConnectAccountStatusData } from "pages/api/stripe/update-connected-account-status";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import { getErrorMessage } from "utils/getErrorMessage";

export default function useUpdateStripeConnectedAccountStatus(): MutationResultPair<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  UpdateConnectAccountStatusData,
  unknown
> {
  return useMutation(
    async data => {
      try {
        return await axios.post(
          "/api/stripe/update-connected-account-status",
          data
        );
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error =>
              `Failed to update Stripe account status: ${error.message}`,
            otherErrorMessage: "Failed to update Stripe account status."
          })
        );
      }
    },
    {
      onSuccess: (_, originalData) => {
        queryCache.invalidateQueries([
          "paymentMethod",
          originalData.paymentMethodId
        ]);
      }
    }
  );
}
