import { useState } from "react";
import { kebabCase } from "lodash";
import { Transition } from "@headlessui/react";
import { compareAsc } from "date-fns";
import { cn } from "utils/cn";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import TableWrapper from "components/admin/table/TableWrapper";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import type { ActivityGroupFullListItem } from "types/model/activity-group";
import { XCircleIcon } from "@heroicons/react/20/solid";
import AdminBookingItemAddModal from "components/admin/AdminBookingItemAddModal";
import type { Field } from "types/model/field";
import type { Venue } from "types/model/venue";
import {
  getActivityTitle,
  getActivityVenueName,
  renderActivityDateString
} from "helpers/helpers";
import type { Client } from "types/model/client";
import type {
  AddBookingItemFormData,
  CreateAdminBookingItem,
  CreateAdminBookingItemsByActivityGroup
} from "types/model/admin-booking";
import QuantityWidget from "components/admin/QuantityWidget";
import SelectedAddOns from "components/site/SelectedAddOns";
import { TicketType } from "types/model/activity";
import {
  getAreAddOnItemsEqual,
  getSortedCreateAdminBookingItemsByActivityGroup
} from "helpers/admin-booking";
import { getActivitiesToAddForAllSessionTicket } from "helpers/activity";

interface AdminBookingItemsProps
  extends UseFieldApiProps<
    CreateAdminBookingItemsByActivityGroup[],
    HTMLElement
  > {
  activityGroups?: ActivityGroupFullListItem[];
  venues?: Venue[];
  activityFields?: Field[];
  client?: Client;
}

const AdminBookingItems = (props: UseFieldApiConfig): React.ReactElement => {
  const [showFullDates, setShowFullDates] = useState({});
  const {
    label,
    activityGroups,
    venues,
    activityFields,
    meta: { error, touched },
    input,
    client
  }: AdminBookingItemsProps = useFieldApi(props);

  const [isAddEditSessionModalOpen, setIsAddEditSessionModalOpen] =
    useState<boolean>(false);

  const handleClickShowAllDates = (e, lineItemId: string) => {
    e.stopPropagation();
    setShowFullDates({
      ...showFullDates,
      [lineItemId]: !showFullDates[lineItemId]
    });
  };

  const onConfirmAddBookingItems = (formData: AddBookingItemFormData): void => {
    const existingItems = input.value || [];

    const updatedItems: CreateAdminBookingItemsByActivityGroup[] =
      formData.ticketItems.reduce((acc, ticketItem) => {
        const activity =
          ticketItem.ticket.type === TicketType.Single &&
          formData.activityGroup.activities.find(
            activity => activity._id === ticketItem.activityId
          );
        const existingItem = acc.find(
          item => item.activityGroup._id === formData.activityGroup._id
        );
        if (existingItem) {
          const existingActivityTicketItem = existingItem.items.find(
            item =>
              item.ticket._id === ticketItem.ticket._id &&
              (item.ticket.type === TicketType.All ||
                (item.ticket.type === TicketType.Single &&
                  item.activities[0]._id === activity._id)) &&
              getAreAddOnItemsEqual(
                item.addOns,
                ticketItem.addOnItems,
                item.ticket.type
              )
          );
          if (existingActivityTicketItem) {
            existingActivityTicketItem.attendees.push({ _id: "" });
          } else {
            existingItem.items.push({
              _id: `temp_${Math.random().toString(36).substring(7)}`,
              activities:
                ticketItem.ticket.type === TicketType.All
                  ? getActivitiesToAddForAllSessionTicket(
                      formData.activityGroup,
                      client
                    )
                  : [activity],
              addOns: ticketItem.addOnItems,
              ticket: ticketItem.ticket,
              attendees: [
                {
                  _id: ""
                }
              ]
            });
          }
        } else {
          acc.push({
            activityGroup: formData.activityGroup,
            items: [
              {
                _id: `temp_${Math.random().toString(36).substring(7)}`,
                activities:
                  ticketItem.ticket.type === TicketType.All
                    ? getActivitiesToAddForAllSessionTicket(
                        formData.activityGroup,
                        client
                      )
                    : [activity],
                addOns: ticketItem.addOnItems,
                ticket: ticketItem.ticket,
                attendees: [
                  {
                    _id: ""
                  }
                ]
              }
            ]
          });
        }
        return acc;
      }, existingItems);

    const sortedItems =
      getSortedCreateAdminBookingItemsByActivityGroup(updatedItems);

    input.onChange(sortedItems);
  };

  const onCancelAddBookingsItems = () => {
    return;
  };

  const handleQuantityChange = (
    action: string,
    activityGroup: ActivityGroupFullListItem,
    item: CreateAdminBookingItem
  ): void => {
    const currentItems = input.value;

    const updatedItems = currentItems.reduce(
      (
        acc: CreateAdminBookingItemsByActivityGroup[],
        bookingItem: CreateAdminBookingItemsByActivityGroup
      ) => {
        if (bookingItem.activityGroup._id === activityGroup._id) {
          const updatedItem = bookingItem.items.reduce((acc, bookingItem) => {
            if (bookingItem._id === item._id) {
              if (action === "increase") {
                acc.push({
                  ...bookingItem,
                  attendees: [...bookingItem.attendees, { _id: "" }]
                });
              } else if (
                action === "decrease" &&
                bookingItem.attendees.length > 1
              ) {
                acc.push({
                  ...bookingItem,
                  attendees: bookingItem.attendees.slice(0, -1)
                });
              }
            } else {
              acc.push(bookingItem);
            }

            return acc;
          }, []);

          acc.push({
            ...bookingItem,
            items: updatedItem
          });
        } else {
          acc.push(bookingItem);
        }

        return acc;
      },
      []
    );

    // Go through activity group items remove ones that have no attendees
    const updatedItemsWithAttendees = updatedItems.reduce(
      (acc: CreateAdminBookingItemsByActivityGroup[], activityGroupItem) => {
        const hasAttendees = activityGroupItem.items.some(
          item => item.attendees.length > 0
        );
        if (hasAttendees) {
          acc.push(activityGroupItem);
        }
        return acc;
      },
      []
    );

    input.onChange(updatedItemsWithAttendees);
  };

  return (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {/* Lets the browser know where to jump to if there's a validation error */}
      <input
        type="text"
        name={input.name}
        style={{ border: 0, padding: 0, width: 0, height: 0, display: "block" }}
      />

      {touched && error && (
        <>
          <div className="mb-5 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p
                  className="text-sm text-red-700"
                  data-cy={`error-${kebabCase(label)}`}
                >
                  {error}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {input.value.length > 0 && (
        <div className="space-y-8">
          {input.value.map(activityGroupItem => (
            <div key={activityGroupItem.activityGroup._id}>
              <h3 className="mb-4 font-medium text-gray-900">
                {getActivityTitle(activityGroupItem.activityGroup)},{" "}
                {getActivityVenueName(activityGroupItem.activityGroup)}
              </h3>
              <TableWrapper shadow={false}>
                <table
                  className="min-w-full divide-y divide-gray-200"
                  data-cy="table-activity-sessions"
                >
                  <thead data-cy="table-head">
                    <tr data-cy="table-head-row">
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500"
                        data-cy="table-head-cell"
                      >
                        Ticket
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500"
                        data-cy="table-head-cell"
                      >
                        Sessions
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500"
                        data-cy="table-head-cell"
                      >
                        Quantity
                      </th>
                      <th
                        className="bg-gray-50 px-6 py-3"
                        data-cy="table-head-cell"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityGroupItem.items.map((item, index) => (
                      <tr className="bg-white" key={index} data-cy="table-row">
                        <td
                          className={cn(
                            "whitespace-no-wrap px-6 py-4 text-sm text-gray-500"
                          )}
                          data-cy="table-cell"
                        >
                          {item.ticket?.name}
                        </td>
                        <td
                          className={cn(
                            "whitespace-no-wrap px-6 py-4 text-sm text-gray-500"
                          )}
                          data-cy="table-cell"
                        >
                          <div>
                            {item.ticket?.type === TicketType.Single ? (
                              <span className="block">
                                {renderActivityDateString({
                                  activityDate: item.activities[0]?.date,
                                  dateOnly: false,
                                  timeOnly: false,
                                  timeZone: client.timeZone
                                })}
                              </span>
                            ) : item.ticket?.type === TicketType.All &&
                              item.activities?.length === 1 ? (
                              <span className="block">
                                {renderActivityDateString({
                                  activityDate: item.activities[0].date,
                                  dateOnly: false,
                                  timeOnly: false,
                                  timeZone: client.timeZone
                                })}
                              </span>
                            ) : item.ticket?.type === TicketType.All &&
                              item.activities?.filter(
                                activity => activity.enabled
                              ).length > 1 ? (
                              <>
                                <span className="block">
                                  {
                                    item.activities.filter(
                                      activity => activity.enabled
                                    ).length
                                  }{" "}
                                  sessions from{" "}
                                  {renderActivityDateString({
                                    activityDate: item.activities
                                      .filter(activity => activity.enabled)
                                      .sort((a, b) =>
                                        compareAsc(
                                          new Date(a.date.start),
                                          new Date(b.date.start)
                                        )
                                      )[0].date,
                                    dateOnly: true,
                                    timeOnly: false,
                                    timeZone: client.timeZone
                                  })}{" "}
                                  [
                                  <a
                                    className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"
                                    onClick={e =>
                                      handleClickShowAllDates(e, item._id)
                                    }
                                  >
                                    {showFullDates[item._id] ? "Hide" : "Show"}
                                  </a>
                                  ]
                                </span>
                                <Transition
                                  show={Boolean(showFullDates[item._id])}
                                >
                                  <Transition.Child
                                    enter="transition-opacity ease-linear duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity ease-linear duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <ul className="mt-2 space-y-2">
                                      {item.activities
                                        .filter(activity => activity.enabled)
                                        .sort((a, b) =>
                                          compareAsc(
                                            new Date(a.date.start),
                                            new Date(b.date.start)
                                          )
                                        )
                                        .map((activity, index) => (
                                          <li
                                            key={index}
                                            className="block text-sm text-gray-500"
                                          >
                                            {renderActivityDateString({
                                              activityDate: activity.date,
                                              dateOnly: false,
                                              timeOnly: false,
                                              timeZone: client.timeZone
                                            })}
                                          </li>
                                        ))}
                                    </ul>
                                  </Transition.Child>
                                </Transition>
                              </>
                            ) : null}
                          </div>
                          {item.addOns?.length > 0 && (
                            <SelectedAddOns
                              customClassName={cn(
                                Boolean(showFullDates[item._id]) && "mt-2"
                              )}
                              addOns={item.addOns}
                              client={client}
                            />
                          )}
                        </td>
                        <td
                          className={cn(
                            "whitespace-no-wrap px-6 py-4 text-sm text-gray-500"
                          )}
                          data-cy="table-cell"
                        >
                          <QuantityWidget
                            quantity={item.attendees.length}
                            onChange={action =>
                              handleQuantityChange(
                                action,
                                activityGroupItem.activityGroup,
                                item
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableWrapper>
            </div>
          ))}
        </div>
      )}

      <div className={cn(input.value.length > 0 && "mt-4 flex space-x-3")}>
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setIsAddEditSessionModalOpen(true)}
          data-cy="btn-add-session"
        >
          <IconPlusCircle className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add new item
        </button>
      </div>

      <AdminBookingItemAddModal
        activityGroups={activityGroups}
        venues={venues}
        activityFields={activityFields}
        isOpen={isAddEditSessionModalOpen}
        client={client}
        setIsOpen={setIsAddEditSessionModalOpen}
        onCancel={onCancelAddBookingsItems}
        onConfirmAddBookingItems={onConfirmAddBookingItems}
      />
    </div>
  );
};

export default AdminBookingItems;
