import { useState } from "react";
import { cn } from "utils/cn";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import UpsellModal from "components/admin/UpsellModal";
import TableWrapper from "components/admin/table/TableWrapper";
import useClient from "hooks/useClient";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import type { Activity, AddOn } from "types/model/activity";
import IconInformationCircleSolid from "public/images/icons/information-circle_solid.svg";
import { formatCurrency } from "helpers/helpers";
import { getIsClientStandardPlanOrHigher } from "helpers/plan-level";
import type { ActivityGroupSalesData } from "types/model/activity-group";
import AddOnTicketSales from "components/admin/AddOnTicketSales";
import type { Client } from "types/model/client";
import type {
  ActivityAddOnFormData
} from "components/admin/ActivityAddOnsAddEditModal";
import ActivityAddOnsAddEditModal from "components/admin/ActivityAddOnsAddEditModal";
import { convertAmountBasedOnCurrency } from "helpers/currency";
import ActivityRestrictedSessions from "components/admin/ActivityRestrictedSessions";

interface ActivityAddOnsProps extends UseFieldApiProps<AddOn[], HTMLElement> {
  isNewActivityGroup?: boolean;
  activityGroupSales?: ActivityGroupSalesData;
  activityGroupSalesIncludingCancelled?: ActivityGroupSalesData;
  activities?: Activity<string>[];
}

const ActivityAddOns = (props: UseFieldApiConfig): React.ReactElement => {
  const {
    isNewActivityGroup,
    activityGroupSales,
    activityGroupSalesIncludingCancelled,
    activities,
    input
  }: ActivityAddOnsProps = useFieldApi(props);
  const [isDirty, setIsDirty] = useState(false);

  const [addOnSalesId, setAddOnSalesId] = useState<string>(null);

  const [editingAddOnId, setEditingAddOnId] = useState<string>(null);
  const [isAddEditAddOnModalOpen, setIsAddEditAddOnModalOpen] =
    useState<boolean>(false);

  const [showUpsellModal, setShowUpsellModal] = useState<boolean>(false);

  const clientQueryInfo = useClient();

  const { getState } = useFormApi();
  const { values } = getState();

  const handleAddNewAddOn = (client: Client): void => {
    if (getIsClientStandardPlanOrHigher(client)) {
      setIsAddEditAddOnModalOpen(true);
    } else {
      setShowUpsellModal(true);
    }
  };

  const onConfirmAddOnAddEdit = (
    formData: ActivityAddOnFormData,
    editingAddOnId = null
  ): void => {
    if (editingAddOnId) {
      const editingAddOnIndex = input.value.findIndex(
        addOn => addOn._id === editingAddOnId
      );

      const updatedAddOns = [...input.value];

      updatedAddOns[editingAddOnIndex] = {
        ...updatedAddOns[editingAddOnIndex],
        name: formData.name,
        price: convertAmountBasedOnCurrency(
          formData.price,
          clientQueryInfo.data.currency
        ),
        restrictSessions: formData.restrictSessions,
        sessionsCanBeUsedFor: formData.sessionsCanBeUsedFor
      };

      input.onChange(updatedAddOns);
    } else {
      const newAddOn: AddOn = {
        _id: `temp_${Math.random().toString(36).substring(7)}`,
        name: formData.name,
        price: convertAmountBasedOnCurrency(
          formData.price,
          clientQueryInfo.data.currency
        ),
        restrictSessions: formData.restrictSessions,
        sessionsCanBeUsedFor: formData.sessionsCanBeUsedFor,
        enabled: true
      };

      const updatedAddOns = [...input.value, newAddOn];

      input.onChange(updatedAddOns);
    }

    setEditingAddOnId(null);
    setIsDirty(true);
  };

  const onCancelAddEditAddOn = () => {
    setEditingAddOnId(null);
  };

  const handleEditAddOn = (id: string, client: Client): void => {
    if (getIsClientStandardPlanOrHigher(client)) {
      setEditingAddOnId(id);
      setIsAddEditAddOnModalOpen(true);
    } else {
      setShowUpsellModal(true);
    }
  };

  const handleUpdateAddOnStatus = (id: string, client: Client): void => {
    if (getIsClientStandardPlanOrHigher(client)) {
      const addOnToUpdateStatusIndex = input.value.findIndex(
        addOn => addOn._id === id
      );

      const updatedAddOnValues = [...input.value];

      updatedAddOnValues[addOnToUpdateStatusIndex].enabled =
        !updatedAddOnValues[addOnToUpdateStatusIndex].enabled;

      input.onChange(updatedAddOnValues);
      setIsDirty(true);
    } else {
      setShowUpsellModal(true);
    }
  };

  const handleDeleteAddOn = (id: string): void => {
    const salesForAddOn = activityGroupSalesIncludingCancelled?.addOnSales[id]
      ? Object.values(activityGroupSalesIncludingCancelled.addOnSales[id])
      : [];

    const hasAddOnAlreadyBeenBooked = salesForAddOn.some(sales => sales > 0);

    if (hasAddOnAlreadyBeenBooked) {
      window.alert(
        "Sorry, this add on cannot be deleted as there have already been bookings for it. Please disable it instead."
      );
      return;
    }

    const updatedAddOns = input.value.filter(addOn => addOn._id !== id);

    input.onChange(updatedAddOns);
    setIsDirty(true);
  };

  return clientQueryInfo.data ? (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {isDirty && !isNewActivityGroup && (
        <div
          className="mb-5 rounded-md bg-blue-50 p-4"
          data-cy="msg-changes-on-save-activity-addons"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <IconInformationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Changes will be applied on save.
              </p>
            </div>
          </div>
        </div>
      )}
      {input.value.length > 0 && (
        <TableWrapper shadow={false}>
          <table
            className="min-w-full divide-y divide-gray-200"
            data-cy="table-activity-addons"
          >
            <thead>
              <tr>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Name
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Price
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Sales
                </th>
                <th className="bg-gray-50 px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {input.value.map((addOn, index) => (
                <tr className="bg-white" key={index}>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm font-medium text-gray-900",
                      !addOn.enabled && "text-opacity-50"
                    )}
                  >
                    {addOn.name}
                    {addOn.restrictSessions && (
                      <ActivityRestrictedSessions
                        activitySessionIds={addOn.sessionsCanBeUsedFor}
                        allActivitySessions={values.datesInstances}
                        client={clientQueryInfo.data}
                      />
                    )}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      !addOn.enabled && "text-opacity-50"
                    )}
                  >
                    {formatCurrency({
                      rawAmount: addOn.price,
                      currency: clientQueryInfo.data.currency
                    })}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      !addOn.enabled && "text-opacity-50"
                    )}
                  >
                    <a
                      className={cn(
                        "cursor-pointer font-medium text-indigo-600 hover:text-indigo-900 focus:underline focus:outline-none"
                      )}
                      onClick={(): void => setAddOnSalesId(addOn._id)}
                    >
                      View details
                    </a>
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium",
                      !addOn.enabled && "text-opacity-50"
                    )}
                  >
                    <a
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() =>
                        handleEditAddOn(addOn._id, clientQueryInfo.data)
                      }
                    >
                      Edit
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() =>
                        handleUpdateAddOnStatus(addOn._id, clientQueryInfo.data)
                      }
                    >
                      {addOn.enabled ? "Disable" : "Enable"}
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => handleDeleteAddOn(addOn._id)}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      <div className={cn(input.value.length > 0 && "mt-4")}>
        <span className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => handleAddNewAddOn(clientQueryInfo.data)}
            data-cy="btn-add-addon"
          >
            <IconPlusCircle
              width={24}
              height={24}
              className="-ml-1 mr-2 h-5 w-5"
            />
            Add new add-on
          </button>
        </span>
      </div>

      <ActivityAddOnsAddEditModal
        isOpen={isAddEditAddOnModalOpen}
        client={clientQueryInfo.data}
        editingAddOnId={editingAddOnId}
        setIsOpen={setIsAddEditAddOnModalOpen}
        onCancel={onCancelAddEditAddOn}
        onConfirmAddOnAddEdit={onConfirmAddOnAddEdit}
      />

      <UpsellModal
        isOpen={showUpsellModal}
        title="Feature not available"
        setIsOpen={setShowUpsellModal}
      >
        <p className="text-sm text-gray-500">
          You discovered a feature that is only available on the Standard and
          Premium plans. Upgrade your subscription plan to unlock it.
        </p>
      </UpsellModal>

      {addOnSalesId && activityGroupSales?.addOnSales[addOnSalesId] && (
        <AddOnTicketSales
          addOn={input.value.find(addOn => addOn._id === addOnSalesId)}
          activities={activities}
          salesData={activityGroupSales.addOnSales[addOnSalesId]}
          client={clientQueryInfo.data}
          onClose={(): void => setAddOnSalesId(null)}
        />
      )}
    </div>
  ) : null;
};

export default ActivityAddOns;
