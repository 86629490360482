import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import { FormTemplateType } from "types/form";
import type { Field } from "types/model/field";
import { FieldType } from "types/model/field";
import type { AdminActivityGroupAccessFiltersFieldData } from "types/model/field-data";
import type { UserReduced } from "types/model/user";
import type { Venue } from "types/model/venue";

const getAdminActivityGroupAccessFiltersInitialValue = (
  adminActivityGroupAccessFilters: AdminActivityGroupAccessFiltersFieldData[]
) => {
  if (!adminActivityGroupAccessFilters?.length) {
    return [{}];
  }

  const fieldInitialValue = adminActivityGroupAccessFilters.map(item => {
    return {
      field: item.field,
      values: item.valueRefVenueArray || item.valueRefArray
    };
  });

  return fieldInitialValue;
};

export const getFieldOptions = (
  field: Field | undefined,
  venuesData: Venue[]
) => {
  if (!field) {
    return [];
  } else if (field.type === FieldType.Venue) {
    return venuesData.map(({ _id, name }) => ({
      value: _id,
      label: name
    }));
  } else {
    return field.fieldOptions?.map(fieldOption => ({
      value: fieldOption._id,
      label: fieldOption.name
    }));
  }
};

interface GenerateCreateAdminUserFormSchemaData {
  formTemplate?: FormTemplateType;
  activityFields: Field[];
  usersFullListData: UserReduced[];
  venuesData: Venue[];
  userId?: string;
}

export const generateCreateAdminUserFormSchema = ({
  formTemplate = FormTemplateType.Seamless,
  activityFields,
  usersFullListData,
  venuesData,
  userId
}: GenerateCreateAdminUserFormSchemaData) => {
  const getFieldsTheCanBeUsedAsFilters = activityFields.filter(
    field =>
      ((field.type === FieldType.Venue && venuesData.length) ||
        (field.type === FieldType.SelectList && field.fieldOptions?.length)) &&
      field.enabled
  );

  let existingUser: UserReduced | undefined;

  if (userId) {
    existingUser = usersFullListData.find(user => user._id === userId);
  }

  const fields = [
    ...(existingUser
      ? [
          {
            index: 0,
            arrayField: false,
            inModal: true,
            component: "user-display",
            name: "userId",
            label: "User",
            formTemplate,
            user: existingUser,
            initialValue: existingUser._id
          }
        ]
      : [
          {
            index: 0,
            arrayField: false,
            inModal: true,
            isRequired: true,
            component: componentTypes.TEXT_FIELD,
            name: "firstName",
            label: "First name",
            formTemplate,
            validate: [
              {
                type: "required"
              }
            ]
          },
          {
            index: 1,
            arrayField: false,
            inModal: true,
            isRequired: true,
            component: componentTypes.TEXT_FIELD,
            name: "lastName",
            label: "Last name",
            formTemplate,
            validate: [
              {
                type: "required"
              }
            ]
          },
          {
            index: 2,
            arrayField: false,
            inModal: true,
            isRequired: true,
            component: componentTypes.TEXT_FIELD,
            name: "email",
            inputType: "email",
            label: "Email",
            formTemplate,
            validate: [
              {
                type: "required"
              }
            ]
          }
        ]),
    {
      index: 1,
      arrayField: false,
      component: componentTypes.SELECT,
      name: "accessLevel",
      label: "Access",
      isRequired: true,
      inModal: true,
      validate: [
        {
          type: "required"
        }
      ],
      options: [
        { value: "10", label: "Full" },
        { value: "4", label: "All attendance registers" },
        { value: "3", label: "Attendance registers that match filters" }
      ],
      formTemplate,
      initialValue: existingUser?.accessLevel
        ? existingUser.accessLevel.toString()
        : ""
    },
    {
      index: 2,
      component: "field-array",
      name: "adminActivityGroupAccessFilters",
      itemLabel: "Filter",
      validate: [
        {
          type: "required"
        }
      ],
      defaultItem: {},
      condition: {
        when: "accessLevel",
        is: "3"
      },
      initialValue: getAdminActivityGroupAccessFiltersInitialValue(
        existingUser?.adminActivityGroupAccessFilters || []
      ),
      fields: [
        {
          index: 0,
          arrayField: true,
          component: componentTypes.SELECT,
          name: "field",
          label: "Field",
          isRequired: true,
          inModal: true,
          validate: [
            {
              type: "required"
            }
          ],
          formTemplate,
          resolveProps: (
            _props: unknown,
            formField: {
              input: {
                name: string;
              };
            },
            formOptions: {
              getState: () => {
                values: {
                  adminActivityGroupAccessFilters: AdminActivityGroupAccessFiltersFieldData[];
                };
              };
            }
          ) => {
            const fieldValue =
              formOptions.getState().values.adminActivityGroupAccessFilters;
            const index = Number(formField.input.name.match(/\d+/)?.[0]);

            const currentValue = fieldValue[index].field;

            const fieldIdsAlreadySelected = fieldValue.reduce((acc, item) => {
              if (item.field !== currentValue) {
                acc.push(item.field);
              }

              return acc;
            }, [] as string[]);

            return {
              options: getFieldsTheCanBeUsedAsFilters
                .filter(field => !fieldIdsAlreadySelected.includes(field._id))
                .map(field => ({
                  value: field._id,
                  label: field.title
                }))
            };
          }
        },
        {
          index: 1,
          arrayField: true,
          component: "checkbox-multiple",
          name: "values",
          label: "Values",
          isRequired: true,
          validate: [
            {
              type: "required"
            }
          ],
          formTemplate,
          resolveProps: (
            _props: unknown,
            formField: {
              input: {
                name: string;
              };
            },
            formOptions: {
              getState: () => {
                values: {
                  adminActivityGroupAccessFilters: AdminActivityGroupAccessFiltersFieldData[];
                };
              };
            }
          ) => {
            const fieldValue =
              formOptions.getState().values.adminActivityGroupAccessFilters;
            const index = Number(formField.input.name.match(/\d+/)?.[0]);

            const fieldId = fieldValue[index].field;

            const field = getFieldsTheCanBeUsedAsFilters.find(
              field => field._id === fieldId
            );

            return {
              options: getFieldOptions(field, venuesData)
            };
          }
        }
      ]
    }
  ];

  const schema = {
    fields
  };

  return schema;
};
