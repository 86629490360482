/**
 * Converts hours and minutes to total minutes.
 * @param hours - Number of hours (number or string)
 * @param minutes - Number of minutes (number or string)
 * @returns Total number of minutes
 */
export function convertToMinutes(
  hours: number | string,
  minutes: number | string
): number {
  const hoursAsNumber = Number(hours);
  const minutesAsNumber = Number(minutes);

  return hoursAsNumber * 60 + minutesAsNumber;
}
