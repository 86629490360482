export type GetPaymentIntentResponse = {
  id: string;
  clientSecret: string;
  country: string;
};

export enum StripePaymentMethods {
  AfterpayClearpay = "afterpay_clearpay",
  ApplePay = "apple_pay",
  Card = "card",
  GooglePay = "google_pay",
  Klarna = "klarna",
  Link = "link",
  PayByBank = "pay_by_bank",
  RevolutPay = "revolut_pay"
}

export type UpdateStripePaymentMethodConfiguration = {
  paymentMethodConfigId: string;
  paymentMethod: StripePaymentMethods;
  enabled: boolean;
};
