import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { cn } from "utils/cn";

interface CreateBookingItemAlertProps {
  color: string;
  children: React.ReactNode;
}

const CreateBookingItemAlert = ({
  color,
  children
}: CreateBookingItemAlertProps) => {
  return (
    <div className="mt-1 flex space-x-1">
      <div className="flex-shrink-0">
        <ExclamationCircleIcon
          className={cn(
            "h-5 w-5",
            color === "red" && "text-red-400",
            color === "yellow" && "text-yellow-400"
          )}
          aria-hidden="true"
        />
      </div>
      <div>
        <p
          className={cn(
            "text-sm font-medium",
            color === "red" && "text-red-700",
            color === "yellow" && "text-yellow-700"
          )}
        >
          {children}
        </p>
      </div>
    </div>
  );
};

export default CreateBookingItemAlert;
