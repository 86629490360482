export const RevolutPayIcon = () => {
  return (
    <div className="grid h-5 w-[31px] place-content-center rounded-[2px] border border-gray-200 bg-white">
      <svg
        viewBox="0 0 5449 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-6"
      >
        <g clipPath="url(#clip0_3844_146129)">
          <path
            d="M3576.13 330.021V208.463H3430.95V49.9766H3290.55V627.775C3290.55 680.865 3303.9 721.324 3330.24 748.032C3356.57 774.763 3397.59 788.314 3452.18 788.314H3576.13V666.756H3485.44C3465.71 666.756 3451.55 662.42 3443.36 653.855C3435.83 646.019 3430.95 626.218 3430.95 603.421V330.021H3576.13Z"
            fill="black"
          />
          <path
            d="M1630.61 208.448L1485.44 630.865L1340.27 208.448H1192.77L1402.34 783.342H1568.54L1778.11 208.448H1630.61Z"
            fill="black"
          />
          <path d="M2579.54 0H2439.14V783.334H2579.54V0Z" fill="black" />
          <path
            d="M3061.71 496.445C3061.71 532.84 3056.19 564.503 3045.32 590.552C3034.53 616.462 3019.07 636.324 2999.37 649.573C2979.67 662.822 2955.45 669.544 2927.4 669.544C2887.26 669.544 2856.97 656.349 2837.38 630.346C2817.59 604.065 2807.55 564.232 2807.55 511.963V208.463H2667.14V527.481C2667.14 577.156 2673.35 619.288 2685.62 652.694C2697.89 686.2 2714.74 713.457 2735.71 733.691C2756.66 753.902 2781.13 768.498 2808.43 777.093C2835.6 785.642 2864.81 789.971 2895.27 789.971C2939.17 789.971 2975.51 782.011 3003.23 766.307C3028.52 751.943 3049.71 735.147 3066.31 716.315L3078.88 783.335H3202.11V208.463H3061.71L3061.71 496.445Z"
            fill="black"
          />
          <path
            d="M2224.51 238.779C2180.26 214.248 2127.73 201.819 2068.37 201.819C2009.75 201.819 1957.4 214.247 1912.79 238.771C1868.12 263.357 1833.03 298.079 1808.48 341.969C1783.96 385.821 1771.53 437.795 1771.53 496.444C1771.53 554.373 1783.96 605.984 1808.48 649.835C1833.01 693.71 1868.12 728.424 1912.79 753.01C1957.4 777.534 2009.75 789.962 2068.37 789.962C2127.73 789.962 2180.27 777.534 2224.51 753.002C2268.8 728.432 2303.71 693.718 2328.26 649.835C2352.78 605.96 2365.22 554.35 2365.22 496.444C2365.22 437.818 2352.78 385.843 2328.26 341.969C2303.7 298.071 2268.8 263.349 2224.51 238.779ZM2150.34 649.542C2127.72 664.27 2100.14 671.743 2068.38 671.743C2037.34 671.743 2009.95 664.271 1986.95 649.535C1963.91 634.784 1945.85 613.961 1933.24 587.641C1920.58 561.298 1914.15 530.618 1914.15 496.446C1914.15 461.569 1920.57 430.711 1933.24 404.724C1945.84 378.76 1963.92 357.93 1986.98 342.807C2009.97 327.699 2037.36 320.041 2068.38 320.041C2100.13 320.041 2127.69 327.699 2150.31 342.791C2173 357.922 2190.91 378.76 2203.53 404.731C2216.19 430.78 2222.61 461.638 2222.61 496.445C2222.61 530.54 2216.19 561.227 2203.53 587.64C2190.9 613.968 2173.01 634.791 2150.34 649.542Z"
            fill="black"
          />
          <path d="M145.915 193.33H0V783.363H145.915V193.33Z" fill="black" />
          <path
            d="M603.499 227.069C603.499 101.869 501.545 0.0078125 376.221 0.0078125H0V126.014H358.333C415.048 126.014 462.029 170.571 463.067 225.335C463.586 252.756 453.294 278.635 434.09 298.203C414.878 317.779 389.207 328.566 361.81 328.566H222.222C217.266 328.566 213.232 332.593 213.232 337.549V449.539C213.232 451.444 213.821 453.264 214.928 454.797L451.762 783.363H625.128L387.744 453.898C507.291 447.897 603.499 347.453 603.499 227.069Z"
            fill="black"
          />
          <path
            d="M1052.16 235.434C1010.12 213.125 960.216 201.819 903.82 201.819C847.354 201.819 796.867 214.255 753.758 238.794C710.58 263.356 676.609 298.086 652.798 342C629.01 385.813 616.953 438.151 616.953 497.567C616.953 555.473 629.196 607.068 653.355 650.927C677.569 694.887 712.492 729.423 757.141 753.575C801.705 777.727 855.158 789.97 916.007 789.97C964.28 789.97 1007.63 780.926 1044.85 763.077C1082.08 745.182 1112.48 720.813 1135.2 690.644C1156.78 661.97 1171.33 629.54 1178.46 594.26L1179.23 590.497H1039.6L1038.94 592.758C1031.26 619.07 1016.34 639.862 994.597 654.567C971.258 670.379 942.592 678.402 909.38 678.402C881.232 678.402 855.81 672.37 833.826 660.476C811.951 648.652 794.845 631.74 782.998 610.197C771.104 588.577 764.336 562.567 762.888 533.041V528.426H1184.64L1185.11 525.847C1186.58 517.755 1187.53 509.415 1187.94 501.029C1188.29 492.86 1188.49 484.698 1188.49 476.459C1187.74 420.76 1175.28 371.767 1151.49 330.827C1127.6 289.84 1094.18 257.743 1052.16 235.434ZM1000.99 343.696C1024.98 363.883 1039.9 392.573 1045.38 429.022H767.735C770.91 405.373 778.762 384.892 791.097 368.088C804.036 350.464 820.615 336.618 840.377 326.931C860.162 317.213 881.882 312.28 904.927 312.28C943.885 312.281 976.206 322.851 1000.99 343.696Z"
            fill="black"
          />
          <path
            d="M4380 257.926C4380 399.839 4280.33 491.932 4128.66 491.932H3931.5V783.335H3837.25V25.0127H4128.66C4280.33 25.0127 4380 116.013 4380 257.926ZM4119.99 405.259C4219.66 405.259 4283.58 347.847 4283.58 257.925C4283.58 168.019 4220.74 111.685 4121.07 111.685H3931.5V405.259H4119.99Z"
            fill="black"
          />
          <path
            d="M4403.87 628.424C4403.87 517.928 4480.78 456.173 4625.94 456.173H4788.44V418.258C4788.44 346.755 4737.53 303.434 4650.86 303.434C4572.85 303.434 4520.86 340.256 4510.02 396.59H4421.19C4434.19 288.265 4521.94 224.354 4655.18 224.354C4796.02 224.354 4877.27 294.764 4877.27 423.678V783.335H4800.36L4792.77 696.677C4758.1 746.497 4702.86 789.833 4607.52 789.833C4486.2 789.832 4403.87 729.172 4403.87 628.424ZM4614.02 714.003C4724.52 714.003 4788.44 650.091 4788.44 558.013V530.924H4617.27C4538.19 530.924 4493.77 560.17 4493.77 623.003C4493.77 677.165 4540.36 714.003 4614.02 714.003Z"
            fill="black"
          />
          <path
            d="M5019.21 230.852L5189.29 671.76L5353.95 230.852H5448.2L5193.62 876.506C5158.95 965.334 5138.36 1000 5060.38 1000H4954.21V918.749H5033.29C5084.2 918.749 5092.87 905.751 5113.46 853.745L5140.53 786.585L4924.96 230.853L5019.21 230.852Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_3844_146129">
            <rect width="5448.2" height="1000" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
