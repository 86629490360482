import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Venue } from "types/model/venue";
import { getErrorMessage } from "utils/getErrorMessage";

export interface CreateVenueData {
  formData: FormDataItem[];
}

export default function useCreateVenue(): MutationResultPair<
  AxiosResponse<Venue>,
  AxiosError<ErrorResponse>,
  CreateVenueData,
  unknown
> {
  return useMutation(
    async data => {
      try {
        return await axios.post("/api/venues", data);
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error => `Failed to create venue: ${error.message}`,
            otherErrorMessage: "Failed to create venue."
          })
        );
      }
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("venuesFullList");
        queryCache.invalidateQueries("venues");
        queryCache.invalidateQueries("client"); // For onboarding status
      }
    }
  );
}
