import type { Field } from "types/model/field";
import type { FieldOption } from "types/model/field-option";
import type { HomeTab } from "types/model/home-tab";
import { HomeTabType } from "types/model/home-tab";
import type { Venue } from "types/model/venue";

export const getListingPathFromAsPath = (asPath: string) => {
  if (!asPath.includes("/listing/")) {
    return null;
  }

  const listingPath = asPath.replace("/listing/", "").split(/[?#]/)[0];

  return listingPath;
};

export const getPathNameFromListingPath = (path: string) => {
  return `/listing/${path}`;
};

export const getConfigInfoForHomeTab = (
  tab: HomeTab,
  activityFieldsData: Field[],
  venuesData: Venue[]
) => {
  if (tab.type === HomeTabType.Venue) {
    const venues = venuesData.filter(v => tab.venues?.includes(v._id));

    return `Activities where Venue is <em>${venues
      .map(item => item.name)
      .join(", ")}</em>`;
  } else if (tab.type === HomeTabType.FieldOption) {
    const field = activityFieldsData.find(f => f._id === tab.field);

    if (!field) return;

    const fieldOptions = field.fieldOptions?.filter(item =>
      tab.fieldOptions?.includes(item._id)
    );

    return `Activities where ${field.title} is <em>${fieldOptions
      ?.map(item => item.name)
      .join(", ")}</em>`;
  } else {
    console.error("Unknown HomeTabType:", tab.type);
  }
};

export const getConfigInfoForHomeTabWithPopulatedValues = (
  tab: HomeTab<Field, FieldOption<string>, Venue>,
  activityFieldsData: Field[],
  venuesData: Venue[]
) => {
  if (tab.type === HomeTabType.Venue) {
    const tabVenueIds = tab.venues?.map(v => v._id);

    const venues = venuesData.filter(v => tabVenueIds?.includes(v._id));

    return `Activities where Venue is <em>${venues
      .map(item => item.name)
      .join(", ")}</em>`;
  } else if (tab.type === HomeTabType.FieldOption) {
    const field = activityFieldsData.find(f => f._id === tab.field._id);

    if (!field) return;

    const tabFieldOptionIds = tab.fieldOptions?.map(f => f._id);
    const fieldOptions = field.fieldOptions?.filter(item =>
      tabFieldOptionIds?.includes(item._id)
    );

    return `Activities where ${field.title} is <em>${fieldOptions
      ?.map(item => item.name)
      .join(", ")}</em>`;
  } else {
    console.error("Unknown HomeTabType:", tab.type);
  }
};
