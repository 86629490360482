import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { wait } from "helpers/helpers";
import type { Client } from "types/model/client";
import type {
  RepeatOption,
  RepeatEndOption,
  ActivityGroupFullListItem
} from "types/model/activity-group";
import { generateAddBookingItemFormSchema } from "helpers/form/admin-booking";
import type { Venue } from "types/model/venue";
import type { Field } from "types/model/field";
import type { AddBookingItemFormData } from "types/model/admin-booking";
export interface ActivitySessionFormData {
  date: string;
  endTime: string;
  startTime: string;
  placeLimit?: string;
  repeatOption?: RepeatOption;
  repeatDays?: string[];
  repeatEndOption?: RepeatEndOption;
  repeatOccurrences?: string;
  repeatEndDate?: string;
}

interface AdminBookingItemAddModalProps {
  isOpen: boolean;
  client: Client;
  activityGroups?: ActivityGroupFullListItem[];
  venues?: Venue[];
  activityFields?: Field[];
  onCancel: () => void;
  onConfirmAddBookingItems: (data: AddBookingItemFormData) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const subFormPrefix = "addBookingItemSubForm";

const AdminBookingItemAddModal = ({
  isOpen,
  client,
  venues,
  activityFields,
  onConfirmAddBookingItems,
  onCancel,
  setIsOpen
}: AdminBookingItemAddModalProps): React.ReactElement => {
  const wrapperRef = useRef();

  const { renderForm, getState, change, blur } = useFormApi();

  const handleSubmit = async () => {
    const { valid: isValid, values, errors } = getState();

    const formData = values[subFormPrefix] as AddBookingItemFormData;
    const formErrors = errors[subFormPrefix];

    if (!isValid && formErrors) {
      for (const key in formErrors) {
        blur(`${subFormPrefix}.${key}`);
      }
      return;
    }

    setIsOpen(false);
    await wait(300); // Wait for modal to transition to not open before updating state stuff.
    onConfirmAddBookingItems(formData);
    change(subFormPrefix, undefined);
  };

  const handleClose = async () => {
    setIsOpen(false);
    await wait(300); // Wait for modal to transition to not open before updating state stuff.
    onCancel();
    change(subFormPrefix, undefined);
  };

  const formSchema = generateAddBookingItemFormSchema({
    venues,
    activityFields,
    client,
    prefix: subFormPrefix,
    handleSubmit,
    handleCancel: handleClose
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={wrapperRef}
        open={isOpen}
        onClose={handleClose}
        data-cy="modal-admin-booking-item-add"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle md:max-w-2xl">
              {/* Added `min-w-full sm:min-w-min` so it goes full width on mobile */}
              <div
                className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                ref={wrapperRef}
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <SquaresPlusIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full sm:ml-4 sm:mt-0">
                    {/* Added w-full`to the above */}
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left"
                    >
                      Add new item
                    </Dialog.Title>
                    <div className="mt-6">{renderForm(formSchema.fields)}</div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AdminBookingItemAddModal;
