import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { getShouldNotRetry } from "helpers/auth";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { Attendee } from "types/model/attendee";
import { handleRequestError } from "utils/handleRequestError";

export const getUserAttendees = async (
  _: string,
  userId: string
): Promise<Attendee[]> => {
  const response: AxiosResponse<Attendee[]> = await axios.get(
    `/api/attendees?userId=${userId}`
  );

  return response.data;
};

export default function useUserAttendees(
  userId: string
): QueryResult<Attendee[], AxiosError<ErrorResponse>> {
  return useQuery(["userAttendees", userId], getUserAttendees, {
    retry: (failureCount: number, error) => {
      return getShouldNotRetry(error.response?.status)
        ? false
        : failureCount < 3;
    },
    onError: handleRequestError,
    enabled: !!userId
  });
}
