import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer";
import { FormSpy, useFieldApi } from "@data-driven-forms/react-form-renderer";
import Badge from "components/Badge";
import { AttendeeAgeWarning } from "features/attendees/components/AttendeeAgeWarning";
import { useCheckAgeEligibility } from "features/attendees/hooks/useCheckAgeEligibility";
import type { ActivitySessionsBookedForAttendee } from "features/bookings/types";
import { getIsSessionBookedForAttendee } from "features/bookings/utils/getIsSessionBookedForAttendee";
import { renderActivityDateString } from "helpers/helpers";
import { TicketType, type Activity, type Ticket } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";
import type { Client } from "types/model/client";
import type { SubscriptionPlan } from "types/model/subscription-plan";
import {
  WaitlistEntryStatus,
  type WaitlistEntry
} from "types/model/waitlistEntry";
import { cn } from "utils/cn";

type Props = UseFieldApiProps<unknown, HTMLElement> & {
  ticketData?: {
    ticket: Ticket<SubscriptionPlan>;
    activities: Activity<string>[];
  }[];
  client?: Client;
  activityGroup?: ActivityGroup;
  userWaitlistEntries?: WaitlistEntry[];
  activitySessionsBookedByUser?: ActivitySessionsBookedForAttendee[];
  attendees?: Attendee[];
  currentAttendee: string;
};

/**
 * Used to select sessions from a list of sessions. Used in the join waitlist
 * form modal. This component is styled uniquely to fit the modal.
 */
const WaitlistSessionOptions = (props: Props) => {
  const {
    label,
    required,
    meta: { error, touched },
    input,
    ticketData = [],
    client,
    activityGroup,
    userWaitlistEntries,
    activitySessionsBookedByUser,
    currentAttendee,
    attendees = []
  } = props;

  const activities = [
    ...new Set(
      ticketData
        .filter(item => item.ticket.type === TicketType.Single)
        .flatMap(item => item.activities)
    )
  ];

  const options = activities.map(option => {
    const hasAlreadyJoinedWaitlist = userWaitlistEntries.some(
      waitlistEntry =>
        waitlistEntry.activity?._id === option._id &&
        waitlistEntry.attendee?._id === currentAttendee &&
        [
          WaitlistEntryStatus.Waiting,
          WaitlistEntryStatus.Pending,
          WaitlistEntryStatus.Ready
        ].includes(waitlistEntry.status)
    );

    const isSessionBookedForAttendee = getIsSessionBookedForAttendee(
      activitySessionsBookedByUser as ActivitySessionsBookedForAttendee[],
      option._id,
      currentAttendee
    );

    return {
      value: option._id,
      label: renderActivityDateString({
        activityDate: option.date,
        dateOnly: false,
        timeOnly: false,
        timeZone: client.timeZone,
        includeYear: false
      }),
      alreadyJoined: hasAlreadyJoinedWaitlist,
      alreadyBooked: isSessionBookedForAttendee,
      disabled: hasAlreadyJoinedWaitlist || isSessionBookedForAttendee
    };
  });

  const handleChange = (value: string) => {
    const currentValue = Array.isArray(input.value) ? input.value : [];
    const newValue = currentValue.includes(value)
      ? currentValue.filter(v => v !== value)
      : [...currentValue, value];
    input.onChange(newValue);
  };

  const hasError = touched && error;

  const { restrictions, hasBlock } = useCheckAgeEligibility({
    userAttendees: attendees,
    currentAttendee,
    activityGroup,
    sessions: activities
  });

  const hasAgeRestrictionBlock =
    restrictions?.isAttendeeDobOutsideAgeRestriction && hasBlock;

  if (!activityGroup || !client || !currentAttendee || !userWaitlistEntries) {
    return null;
  }

  return (
    <div className="mt-4 flex flex-col gap-1">
      <p className="mt-px block pt-2 text-sm font-medium leading-5 text-gray-700 sm:mt-0 sm:pt-0">
        {label}
        {required && "*"}
      </p>
      <div className="mt-1 flex flex-col gap-1 space-y-1">
        {options.map(option => {
          const checked =
            (Array.isArray(input.value) &&
              input.value.includes(option.value)) ||
            option.alreadyJoined;

          return (
            <div key={option.value} className="flex min-h-[21px] items-center">
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                name={input.name}
                disabled={
                  option.disabled || !currentAttendee || hasAgeRestrictionBlock
                }
                checked={checked}
                onChange={() => handleChange(option.value)}
                className={cn(
                  "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500",
                  (option.disabled || hasAgeRestrictionBlock) && "opacity-50"
                )}
              />
              <label
                htmlFor={option.value}
                className="ml-2 block text-sm text-gray-900"
              >
                <span
                  className={cn(
                    (option.disabled ||
                      !currentAttendee ||
                      hasAgeRestrictionBlock) &&
                      "opacity-50"
                  )}
                >
                  {option.label}
                </span>
                {option.alreadyJoined && (
                  <Badge color="blue" className="ml-2">
                    Joined waitlist
                  </Badge>
                )}
                {option.alreadyBooked && (
                  <Badge color="blue" className="ml-2">
                    Already booked
                  </Badge>
                )}
              </label>
            </div>
          );
        })}
        <AttendeeAgeWarning
          userAttendees={attendees}
          currentAttendee={currentAttendee}
          activityGroup={activityGroup}
          sessions={activities}
        />
      </div>
      {hasError && <div className="mt-1 text-sm text-red-500">{error}</div>}
    </div>
  );
};

/**
 * Used to select sessions from a list of sessions. Used in the join waitlist
 * form modal. This component is styled uniquely to fit the modal.
 */
export const SelectWaitlistSessions = (props: UseFieldApiConfig) => {
  const fieldProps = useFieldApi(props);

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <WaitlistSessionOptions
          {...fieldProps}
          currentAttendee={values.attendee}
        />
      )}
    </FormSpy>
  );
};
