import { useState } from "react";
import { cn } from "utils/cn";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import TableWrapper from "components/admin/table/TableWrapper";
import useClient from "hooks/useClient";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import IconInformationCircleSolid from "public/images/icons/information-circle_solid.svg";
import type { HomeTab, HomeTabFormData} from "types/model/home-tab";
import { HomeTabType } from "types/model/home-tab";
import { getConfigInfoForHomeTab } from "helpers/listings";
import { createMarkup } from "helpers/string";
import type { Field} from "types/model/field";
import { FieldType } from "types/model/field";
import HomeTabsAddEditModal from "components/admin/HomeTabsAddEditModal";
import type { Venue } from "types/model/venue";

interface HomeTabsProps extends UseFieldApiProps<HomeTab[], HTMLElement> {
  activityFieldsData?: Field[];
  venuesData?: Venue[];
}

const HomeTabs = (props: UseFieldApiConfig): React.ReactElement => {
  const { input, activityFieldsData, venuesData }: HomeTabsProps =
    useFieldApi(props);

  const [isDirty, setIsDirty] = useState(false);
  const clientQueryInfo = useClient();

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<string>(null);

  const handleAddNewTab = (): void => {
    setIsAddEditModalOpen(true);
  };

  const onEdit = (id: string) => {
    setEditingId(id);
    setIsAddEditModalOpen(true);
  };

  const onCancelAddEdit = () => {
    setEditingId(null);
  };

  const onConfirmAddEdit = (
    formData: HomeTabFormData,
    editingId: string = null
  ): void => {
    const field = activityFieldsData.find(
      field => field._id === formData.field
    );

    const type: HomeTabType =
      field.type === FieldType.Venue
        ? HomeTabType.Venue
        : HomeTabType.FieldOption;

    if (editingId) {
      const existingIndex = input.value.findIndex(
        item => item._id === editingId
      );

      const updatedItems = [...input.value];

      updatedItems[existingIndex] = {
        ...updatedItems[existingIndex],
        name: formData.name,
        path: formData.path,
        type,
        field: formData.field,
        ...(type === HomeTabType.FieldOption && {
          fieldOptions: formData.value.filter(id => {
            const fieldOptionIds = field.fieldOptions.map(
              fieldOption => fieldOption._id
            );
            return fieldOptionIds.includes(id);
          }),
          venues: []
        }),
        ...(type === HomeTabType.Venue && {
          venues: formData.value,
          fieldOptions: []
        })
      };

      input.onChange(updatedItems);
    } else {
      const newHomeTab = {
        _id: `temp_${Math.random().toString(36).substring(7)}`,
        name: formData.name,
        path: formData.path,
        type,
        field: formData.field,
        ...(type === HomeTabType.FieldOption && {
          fieldOptions: formData.value.filter(id => {
            const fieldOptionIds = field.fieldOptions.map(
              fieldOption => fieldOption._id
            );
            return fieldOptionIds.includes(id);
          }),
          venues: []
        }),
        ...(type === HomeTabType.Venue && {
          venues: formData.value,
          fieldOptions: []
        }),
        enabled: true
      };

      const updatedItems = [...input.value, newHomeTab];
      input.onChange(updatedItems);
    }

    setEditingId(null);
    setIsDirty(true);
  };

  const onDelete = (id: string): void => {
    const updatedItems = input.value.filter(item => item._id !== id);
    input.onChange(updatedItems);
    setIsDirty(true);
  };

  const onUpdateTabStatus = (id: string) => {
    const toUpdateIndex = input.value.findIndex(item => item._id === id);

    const updatedValues = [...input.value];

    updatedValues[toUpdateIndex].enabled =
      !updatedValues[toUpdateIndex].enabled;

    input.onChange(updatedValues);
    setIsDirty(true);
  };

  return clientQueryInfo.data ? (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {isDirty && (
        <div className="mb-5 rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <IconInformationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Changes will be applied on save.
              </p>
            </div>
          </div>
        </div>
      )}
      {input.value.length > 0 && (
        <TableWrapper shadow={false}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Name
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Path
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Configuration
                </th>
                <th className="bg-gray-50 px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {input.value.map((tab, index) => (
                <tr className="bg-white" key={index}>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm font-medium text-gray-900",
                      !tab.enabled && "text-opacity-50"
                    )}
                  >
                    {tab.name}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      !tab.enabled && "text-opacity-50"
                    )}
                  >
                    {`/listing/${tab.path}`}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      !tab.enabled && "text-opacity-50"
                    )}
                  >
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        getConfigInfoForHomeTab(
                          tab,
                          activityFieldsData,
                          venuesData
                        )
                      )}
                    />
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium"
                    )}
                  >
                    <a
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => onEdit(tab._id)}
                    >
                      Edit
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => onUpdateTabStatus(tab._id)}
                    >
                      {tab.enabled ? "Disable" : "Enable"}
                    </a>
                    {" | "}

                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => onDelete(tab._id)}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      <div className={cn(input.value.length > 0 && "mt-4")}>
        <span className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => handleAddNewTab()}
          >
            <IconPlusCircle
              width={24}
              height={24}
              className="-ml-1 mr-2 h-5 w-5"
            />
            Add new tab
          </button>
        </span>
      </div>

      <HomeTabsAddEditModal
        isOpen={isAddEditModalOpen}
        editingId={editingId}
        activityFieldsData={activityFieldsData}
        venuesData={venuesData}
        setIsOpen={setIsAddEditModalOpen}
        onCancel={onCancelAddEdit}
        onConfirmAddEdit={onConfirmAddEdit}
      />
    </div>
  ) : null;
};

export default HomeTabs;
