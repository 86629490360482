import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import axios from "axios";
import { StripeConnectedAccountStatusInfo } from "components/admin/StripeConnectedAccountStatusInfo";
import UpdateStripeConnectedAccountStatusConfirmModal from "components/admin/UpdateStripeConnectedAccountStatusConfirmModal";
import Badge from "components/Badge";
import { SubmitButton } from "components/form/SubmitButton";
import { fromUnixTime } from "date-fns";
import { StripePaymentConfiguration } from "features/stripe/components/StripePaymentConfiguration";
import { formatDate } from "helpers/date";
import { ExternalLink } from "lucide-react";
import { useState } from "react";
import type { Client } from "types/model/client";
import type { StripeConnectedAccountWithStripeAccount } from "types/model/payment";
import { cn } from "utils/cn";

type StripeConnectionProps = UseFieldApiProps<{ name: string }, HTMLElement> & {
  label?: string;
  paymentMethodId?: string;
  stripeConnectedAccounts?: StripeConnectedAccountWithStripeAccount[];
  clientHasActiveSubscriptions?: boolean;
  client?: Client;
};

export const StripeConnection = (props: UseFieldApiConfig) => {
  const {
    input,
    label,
    paymentMethodId,
    stripeConnectedAccounts,
    clientHasActiveSubscriptions,
    client
  }: StripeConnectionProps = useFieldApi(props);

  const [
    isUpdateStripeConnectedAccountStatusConfirmModalOpen,
    setIsUpdateStripeConnectedAccountStatusConfirmModalOpen
  ] = useState<boolean>(false);

  const [
    connectedAccountUpdatingStatusFor,
    setConnectedAccountUpdatingStatusFor
  ] = useState<StripeConnectedAccountWithStripeAccount | null>(null);

  const [isLoadingStripeAccountLinkUrl, setLoadingStripeAccountLinkUrl] =
    useState<boolean>(false);

  const handleConnectNewStripeAccount = async (accountId?: string) => {
    setLoadingStripeAccountLinkUrl(true);
    try {
      const response = await axios.get(
        `/api/payment-methods/stripe-onboard${
          accountId ? `?accountId=${accountId}` : ""
        }`
      );
      setLoadingStripeAccountLinkUrl(false);
      window.location = response.data.accountLinkURL;
    } catch (error) {
      setLoadingStripeAccountLinkUrl(false);
      console.log({ error });
    }
  };

  const handleUpdateConnectedAccountStatus = async (
    connectedAccount: StripeConnectedAccountWithStripeAccount
  ) => {
    setConnectedAccountUpdatingStatusFor(connectedAccount);
    setIsUpdateStripeConnectedAccountStatusConfirmModalOpen(true);
  };

  return (
    <div
      className={cn(
        "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        "mt-5 border-t border-gray-200 pt-5",
        "mt-5"
      )}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
      </label>
      <div className="mt-3 sm:col-span-2 sm:mt-1">
        {stripeConnectedAccounts?.length === 0 ? (
          <div>
            <SubmitButton
              isLoading={isLoadingStripeAccountLinkUrl}
              onClick={() => handleConnectNewStripeAccount()}
              disabled={isLoadingStripeAccountLinkUrl}
              type="primary"
            >
              Set up Stripe account
            </SubmitButton>
            <p className="mt-2 text-sm text-gray-500">
              In order to receive Credit and Debit card payments please set up a
              Stripe account.
            </p>
          </div>
        ) : (
          <div className="space-y-4 lg:max-w-lg">
            {stripeConnectedAccounts
              ?.sort(
                (a, b) =>
                  Number(b.stripeAccount.created) -
                  Number(a.stripeAccount.created)
              )
              .map(stripeConnectedAccount => (
                <div
                  key={stripeConnectedAccount.accountId}
                  className="rounded-md bg-gray-50 px-6 py-5 lg:flex lg:items-start lg:justify-between"
                >
                  <div className="mt-3 lg:mt-0">
                    <div className="flex">
                      <span className="block font-mono text-sm font-medium text-gray-900">
                        {stripeConnectedAccount.accountId}
                      </span>
                      {stripeConnectedAccount.connected ? (
                        <>
                          {stripeConnectedAccount.stripeAccount
                            .details_submitted &&
                          stripeConnectedAccount.stripeAccount
                            .charges_enabled &&
                          stripeConnectedAccount.stripeAccount
                            .payouts_enabled ? (
                            <Badge color="green" className="ml-2.5">
                              Connected
                            </Badge>
                          ) : (
                            <Badge color="yellow" className="ml-2.5">
                              Pending
                            </Badge>
                          )}
                        </>
                      ) : (
                        <Badge color="red" className="ml-2.5">
                          Disconnected
                        </Badge>
                      )}
                    </div>
                    {client && stripeConnectedAccount.stripeAccount.created && (
                      <div className="mt-1.5 text-sm text-gray-600">
                        Created on{" "}
                        {formatDate(
                          fromUnixTime(
                            stripeConnectedAccount.stripeAccount.created
                          ),
                          "d MMM yyyy",
                          client.timeZone
                        )}
                      </div>
                    )}
                    <StripeConnectedAccountStatusInfo
                      detailsSubmitted={
                        stripeConnectedAccount.stripeAccount.details_submitted
                      }
                      chargesEnabled={
                        stripeConnectedAccount.stripeAccount.charges_enabled
                      }
                      payoutsEnabled={
                        stripeConnectedAccount.stripeAccount.payouts_enabled
                      }
                    />
                    {(!stripeConnectedAccount.stripeAccount.details_submitted ||
                      !stripeConnectedAccount.stripeAccount.charges_enabled ||
                      !stripeConnectedAccount.stripeAccount
                        .payouts_enabled) && (
                      <div className="mt-4">
                        <SubmitButton
                          isLoading={isLoadingStripeAccountLinkUrl}
                          onClick={() =>
                            handleConnectNewStripeAccount(
                              stripeConnectedAccount.stripeAccount.id
                            )
                          }
                          disabled={isLoadingStripeAccountLinkUrl}
                          type="primary"
                        >
                          Continue account setup
                        </SubmitButton>
                      </div>
                    )}
                    {stripeConnectedAccount.connected && (
                      <StripePaymentConfiguration client={client} />
                    )}
                    {stripeConnectedAccount.stripeAccount.details_submitted &&
                      stripeConnectedAccount.stripeAccount.charges_enabled &&
                      stripeConnectedAccount.stripeAccount.payouts_enabled && (
                        <div className="mt-4">
                          <a
                            href={`https://dashboard.stripe.com/${stripeConnectedAccount.accountId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="group flex items-center gap-1"
                          >
                            <ExternalLink className="h-5 w-5 text-indigo-600 group-hover:text-indigo-900 group-hover:outline-none" />
                            <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-900 group-hover:outline-none">
                              Stripe dashboard
                            </span>
                          </a>
                        </div>
                      )}
                  </div>
                  <div className="mt-4 space-x-2 lg:ml-6 lg:mt-0 lg:flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() =>
                        handleUpdateConnectedAccountStatus(
                          stripeConnectedAccount
                        )
                      }
                    >
                      {stripeConnectedAccount.connected
                        ? "Disconnect"
                        : "Connect"}
                    </button>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      {connectedAccountUpdatingStatusFor &&
        paymentMethodId &&
        clientHasActiveSubscriptions && (
          <UpdateStripeConnectedAccountStatusConfirmModal
            connectedAccount={connectedAccountUpdatingStatusFor}
            paymentMethodId={paymentMethodId}
            clientHasActiveSubscriptions={clientHasActiveSubscriptions}
            isOpen={isUpdateStripeConnectedAccountStatusConfirmModalOpen}
            setIsOpen={setIsUpdateStripeConnectedAccountStatusConfirmModalOpen}
          />
        )}
    </div>
  );
};
