import type { SessionPassTransactionsToCreateForRecreditItem } from "helpers/server/session-pass";
import type { DateDuration } from "types/date";
import type { AccountCreditExpiresAfter } from "types/model/account-credit";
import type { ActivityGroup } from "types/model/activity-group";
import type { SessionPass } from "types/model/session-pass";

export interface CancellationWindowValue {
  amount: number;
  unit: DateDuration;
}

interface CancellationPolicyRuleConfig {
  refundMethods: RefundMethod[];
  accountCreditExpiry: AccountCreditExpiresAfter;
  fee: number;
  percentageToRefund: number;
}

export interface CancellationPolicyRule {
  _id: string;
  cancellationWindow: CancellationWindowValue;
  config: CancellationPolicyRuleConfig;
}

export interface CancellationPolicy<AGs = string[]> {
  _id: string;
  name: string;
  appliesToAllActivityGroups: boolean;
  activityGroups?: AGs;
  rules: CancellationPolicyRule[];
  client: string;
  enabled: boolean;
}

export interface PassCreditRefundItem {
  passDebitLineItemId: string;
  sessionPassId: string;
  name: string;
  passCredits: number;
  amount: number;
  expiresDates: {
    passCredits: number;
    expires?: Date;
  }[];
}

export interface PassCreditRefundWithExpiresInfo {
  name: string;
  passCredits: number;
  expires?: Date;
}

export interface AccountCreditRefund {
  accountCreditItemId: string;
  amount: number;
  isNewCreditItem: boolean;
  expires?: Date;
}

export interface RefundOption {
  passCredit: PassCreditRefundItem[];
  stripe: StripeRefund[];
  creditOnAccount: AccountCreditRefund[];
  refundTotal: number;
  feeAmount: number;
  passDebitAdjustment: number;
  discountAdjustment: number;
}

export interface PassDebitRefundStep {
  passCredits: number;
  amount: number;
  discountAdjustment?: number;
  feeAmount?: number;
  passDebitAdjustment?: number;
  sessionPassTransactionsToCreateForRecredit?: SessionPassTransactionsToCreateForRecreditItem[];
}

export interface PassDebitRefund {
  _id: string;
  sessionPass: SessionPass;
  steps: PassDebitRefundStep[];
}

export interface ExistingCreditItemUsed {
  accountCreditItemId: string;
  amount: number;
  expires: Date;
}

export interface AvailableStripeRefund {
  paymentIntentId: string;
  amount: number;
  last4: string;
  brand: string;
}

export interface StripeRefund {
  paymentIntentId: string;
  amount: number;
  last4: string;
  brand: string;
}

export enum RefundMethod {
  Stripe = "stripe",
  AccountCredit = "accountCredit"
}

export enum CancellationPolicyAssignmentType {
  AllActivityGroups = "allActivityGroups",
  SelectedActivityGroups = "selectedActivityGroups"
}

export interface CancellationPolicyRuleFormData {
  _id?: string;
  cancellationWindow?: {
    amount?: string;
    unit?: DateDuration;
  };
  accountCreditExpiry?: {
    amount?: string;
    unit?: DateDuration;
  };
  refundMethods?: RefundMethod[];
  percentageToRefund?: string;
  fee?: string;
}

export interface CancellationPolicyFormData<AGs = ActivityGroup[]> {
  _id?: string;
  name?: string;
  assignmentType?: CancellationPolicyAssignmentType;
  activityGroups?: AGs;
  rules?: CancellationPolicyRule[];
  enabled?: boolean;
}

export enum RefundEligibility {
  FullRefund = "fullRefund",
  PartialRefund = "partialRefund",
  NoRefund = "noRefund"
}

export interface LineItemRefundAmount {
  lineItemId: string;
  totalWithAddOns: number;
  totalWithoutAddOns?: number; // not used in addOnLineItemRefundAmounts
  feeToCharge: number;
  amountAfterDeductions: number;
  refundEligibility: RefundEligibility;
  fee?: number; // taken from cancellation rule config
  percentageToRefund?: number; // taken from cancellation rule config
}
