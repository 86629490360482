import { Switch } from "components/ui/switch";
import { useUpdateStripePaymentMethodConfiguration } from "features/stripe/hooks/useUpdateStripePaymentMethodConfiguration";
import type { StripePaymentMethods } from "features/stripe/types";
import type { ReactNode } from "react";

type Props = {
  paymentMethodConfigId: string;
  paymentMethod: StripePaymentMethods;
  displayName: string;
  enabled: boolean;
  disabled?: boolean;
  icon?: ReactNode;
};

export const StripePaymentMethod = ({
  paymentMethodConfigId,
  paymentMethod,
  displayName,
  enabled,
  disabled,
  icon
}: Props) => {
  const [enableStripePaymentMethods, { isLoading }] =
    useUpdateStripePaymentMethodConfiguration();

  return (
    <li className="flex items-center justify-between">
      <h4 className="flex w-full items-center gap-1 text-sm font-normal leading-normal text-gray-600">
        {icon}
        {displayName}
      </h4>
      <Switch
        checked={enabled}
        disabled={isLoading || disabled}
        onCheckedChange={async checked => {
          await enableStripePaymentMethods({
            paymentMethodConfigId,
            paymentMethod,
            enabled: checked
          });
        }}
      />
    </li>
  );
};
