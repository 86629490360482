import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import Badge from "components/Badge";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

const CheckboxMultiple = (props): React.ReactElement => {
  const {
    label,
    input,
    isRequired,
    isAdminOnly,
    meta: { error, touched },
    index,
    helpText,
    inModal,
    options,
    arrayField,
    isSubFormSubmitted,
    formTemplate = FormTemplateType.Default
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  const handleChange = event => {
    const target = event.target;
    const updatedValue = target.checked
      ? [...input.value, target.id]
      : input.value.filter(item => item !== target.id);
    input.onChange(updatedValue);
  };

  return options.length ? (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label
        className={cn(
          "text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
          !isAdminOnly && "block",
          isAdminOnly && "flex flex-wrap gap-2"
        )}
      >
        {label}
        {isRequired && "*"}
        {isAdminOnly && <Badge>Admin only</Badge>}
      </label>
      <div
        className={cn(
          isDefaultFormTemplate && "mt-2 sm:col-span-2 sm:mt-1 sm:max-w-sm",
          isSeamlessFormTemplate && "mt-2"
        )}
      >
        <div className="space-y-2">
          {options.map(option => (
            <div key={option.value} className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id={option.value}
                  name={option.value}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={input.value.includes(option.value)}
                  onChange={handleChange}
                  data-cy={`form-field-${kebabCase(option.label)}`}
                  {...(option.disabled && { disabled: true })}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={option.value} className="text-gray-700">
                  {option.label}
                </label>
              </div>
            </div>
          ))}
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p className="mt-3 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  ) : null;
};

export default CheckboxMultiple;
