import type { AxiosResponse } from "axios";
import axios from "axios";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";
import { getErrorMessage } from "utils/getErrorMessage";

export default function useCreateAttendee(): MutationResultPair<
  AxiosResponse<Attendee>,
  ErrorResponse,
  FormDataItem,
  unknown
> {
  return useMutation(
    async data => {
      try {
        return await axios.post("/api/attendees", data);
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error =>
              `Failed to create add-on booking: ${error.message}`,
            otherErrorMessage: "Failed to create add-on booking."
          })
        );
      }
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(["userAttendees", response.data.user]);
      }
    }
  );
}
