import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

const RadioGroupWithDescriptonPlain = (props): React.ReactElement => {
  const {
    label,
    helpText,
    input,
    options,
    isDisabled,
    isSubFormSubmitted,
    arrayField,
    inModal = false,
    formTemplate = FormTemplateType.Default,
    isRequired,
    index,
    meta: { error, touched }
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label
        className="hidden text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:block sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div className="relative mt-1 sm:col-span-2 sm:max-w-sm">
        <fieldset>
          <legend className="sr-only">{label}</legend>
          <div
            className={cn(
              isDefaultFormTemplate && "space-y-5",
              isSeamlessFormTemplate && "mt-1 space-y-3"
            )}
          >
            {options.map(option => (
              <div key={option.value} className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id={option.value}
                    aria-describedby={`${option.value}-description`}
                    name={input.name}
                    type="radio"
                    disabled={isDisabled || option.disabled}
                    defaultChecked={option.value === input.value}
                    className={cn(
                      "h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500",
                      isDisabled && option.value !== input.value && "opacity-50"
                    )}
                    onChange={input.onChange}
                    value={option.value}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor={option.value}
                    className={cn(
                      "font-medium text-gray-700",
                      (isDisabled || option.disabled) &&
                        option.value !== input.value &&
                        "opacity-50"
                    )}
                  >
                    {option.label}
                  </label>
                  <p
                    id={`${option.value}-description`}
                    className={cn(
                      "text-gray-500",
                      (isDisabled || option.disabled) &&
                        option.value !== input.value &&
                        "opacity-50"
                    )}
                  >
                    {option.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </fieldset>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
};

export default RadioGroupWithDescriptonPlain;
