import axios from "axios";
import produce from "immer";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";
import type { CurrentUser } from "types/model/user";
import { getErrorMessage } from "utils/getErrorMessage";

interface SaveAttendeeResponse {
  data: Attendee;
}

interface SaveAttendeeData {
  id: string;
  formData: FormDataItem[];
}

export default function useUserSaveAttendee(): MutationResultPair<
  SaveAttendeeResponse,
  ErrorResponse,
  SaveAttendeeData,
  unknown
> {
  return useMutation(
    async data => {
      try {
        return await axios.put(`/api/user/attendees/${data.id}`, data.formData);
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error => `Failed to save attendee: ${error.message}`,
            otherErrorMessage: "Failed to save attendee."
          })
        );
      }
    },
    {
      onSuccess: response => {
        const updatedAttendee = response.data;

        const currentUserData: CurrentUser | undefined =
          queryCache.getQueryData("currentUser");

        if (!currentUserData) return;

        const currentUserDataUpdated = produce<CurrentUser>(
          currentUserData,
          (draft: CurrentUser) => {
            if (!draft.attendees) return;

            const attendeeToUpdateIndex = draft?.attendees?.findIndex(
              attendee => attendee._id === updatedAttendee._id
            );

            if (draft.attendees && attendeeToUpdateIndex !== -1) {
              draft.attendees[attendeeToUpdateIndex] = updatedAttendee;
            }
          }
        );

        queryCache.setQueryData("currentUser", currentUserDataUpdated);
        queryCache.invalidateQueries("currentUser");
        queryCache.invalidateQueries("basket");
      }
    }
  );
}
