import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { Button } from "components/ui/button";
import { LockIcon } from "lucide-react";

interface UpgradePlanProps
  extends UseFieldApiProps<{ name: string }, HTMLElement> {
  targetPlan?: string;
}

const UpgradePlan = (props: UseFieldApiConfig): React.ReactElement => {
  const { targetPlan = "Premium" }: UpgradePlanProps = useFieldApi(props);

  const handleGoManageSubscription = () => {
    window.open("/admin/settings/subscription", "_blank");
  };

  return (
    <div className="mt-5 border-t border-gray-200 pt-5">
      <div className="space-y-4">
        <div className="flex items-center text-sm text-gray-500">
          <LockIcon className="mr-2 h-4 w-4" />
          Waitlists are only available on the {targetPlan} plan.
        </div>
        <Button
          variant="secondary"
          className="w-full sm:max-w-sm"
          onClick={handleGoManageSubscription}
        >
          Upgrade to {targetPlan}
        </Button>
      </div>
    </div>
  );
};

export default UpgradePlan;
