import { useState } from "react";
import { kebabCase } from "lodash";
import { compareAsc } from "date-fns";
import { cn } from "utils/cn";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import TableWrapper from "components/admin/table/TableWrapper";
import useClient from "hooks/useClient";
import {
  formatDate,
  getDateEndOfDay,
  getStartEndDatesFromInput
} from "helpers/date";
import { getActivityDateInstancesWithRepeats } from "helpers/activity";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import type { Activity, ActivityDateInstance } from "types/model/activity";
import type {
  ActivityGroupSalesData} from "types/model/activity-group";
import {
  RepeatOption
} from "types/model/activity-group";
import {
  XCircleIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/20/solid";
import type {
  ActivitySessionFormData
} from "components/admin/ActivitySessionsAddEditModal";
import ActivitySessionsAddEditModal from "components/admin/ActivitySessionsAddEditModal";
import ActivitySessionsBulkUpdatePlaceLimitsModal from "components/admin/ActivitySessionsBulkUpdatePlaceLimitsModal";

interface ActivityDateInstancesProps
  extends UseFieldApiProps<Activity<string>[], HTMLElement> {
  isNewActivityGroup?: boolean;
  activityGroupSales?: ActivityGroupSalesData;
  activityGroupSalesIncludingCancelled?: ActivityGroupSalesData;
}

const ActivityDateInstances = (
  props: UseFieldApiConfig
): React.ReactElement => {
  const {
    isNewActivityGroup,
    activityGroupSales,
    activityGroupSalesIncludingCancelled,
    label,
    meta: { error, touched },
    input
  }: ActivityDateInstancesProps = useFieldApi(props);

  const { getState } = useFormApi();
  const formState = getState();

  const hasAllSessionsOrSubscriptionTicketSales = activityGroupSales
    ? Object.entries(activityGroupSales.allSessionTicketSales).some(
        ([, value]) => value > 0
      ) ||
      Object.entries(activityGroupSales.subscriptionTicketSales).some(
        ([, value]) => value > 0
      )
    : false;

  const [isAddEditSessionModalOpen, setIsAddEditSessionModalOpen] =
    useState<boolean>(false);
  const [
    isBulkUpdatePlaceLimitsModalOpen,
    setIsBulkUpdatePlaceLimitsModalOpen
  ] = useState<boolean>(false);
  const [editingSessionId, setEditingSessionId] = useState<string>(null);

  const clientQueryInfo = useClient();

  const onConfirmSessionAddEdit = (
    formData: ActivitySessionFormData,
    editingSessionId = null
  ): void => {
    const { start, end } = getStartEndDatesFromInput({
      dateString: formData.date,
      startTime: formData.startTime,
      endTime: formData.endTime,
      timeZone: clientQueryInfo.data.timeZone
    });

    if (editingSessionId) {
      const editingSession = input.value.find(
        item => item._id === editingSessionId
      );

      editingSession.date = {
        start,
        end
      };

      editingSession.placeLimit = parseInt(formData.placeLimit, 10);

      const sessionsWithEditingRemoved = input.value.filter(
        item => item._id !== editingSessionId
      );

      const updatedDateInstances = [
        ...sessionsWithEditingRemoved,
        editingSession
      ];

      input.onChange(updatedDateInstances);
    } else {
      let newDateInstances: ActivityDateInstance[];
      if (
        formData.repeatOption === RepeatOption.Daily ||
        formData.repeatOption === RepeatOption.Weekly
      ) {
        newDateInstances = getActivityDateInstancesWithRepeats({
          start,
          end,
          ...(formData.placeLimit && {
            placeLimit: parseInt(formData.placeLimit, 10)
          }),
          repeatOption: formData.repeatOption,
          ...(formData.repeatDays && {
            repeatDays: formData.repeatDays.map(day => parseInt(day, 10) as Day)
          }),
          repeatEndOption: formData.repeatEndOption,
          ...(formData.repeatOccurrences && {
            repeatOccurrences: parseInt(formData.repeatOccurrences, 10)
          }),
          ...(formData.repeatEndDate && {
            repeatEndDate: getDateEndOfDay(
              new Date(formData.repeatEndDate),
              clientQueryInfo.data.timeZone
            )
          })
        });
      } else {
        newDateInstances = [
          {
            _id: `temp_${Math.random().toString(36).substring(7)}`,
            enabled: true,
            placeLimit: formData.placeLimit
              ? parseInt(formData.placeLimit, 10)
              : null,
            date: {
              start,
              end
            }
          }
        ];
      }

      const dateInstances = [...input.value, ...newDateInstances];

      input.onChange(dateInstances);
    }
    setEditingSessionId(null);
  };

  const onCancelAddEditSession = () => {
    setEditingSessionId(null);
  };

  const onUpdateSessionStatus = (id: string): void => {
    const dateInstanceToDisable = input.value.find(
      activityDateInstance => activityDateInstance._id === id
    );

    dateInstanceToDisable.enabled = !dateInstanceToDisable.enabled;

    const dateInstancesWithInstanceToDisabledRemoved = input.value.filter(
      activityDateInstance => activityDateInstance._id !== id
    );

    const updatedDateInstances = [
      ...dateInstancesWithInstanceToDisabledRemoved,
      dateInstanceToDisable
    ];

    input.onChange(updatedDateInstances);
  };

  const onDeleteSession = (id: string): void => {
    if (activityGroupSalesIncludingCancelled?.activitySales[id] > 0) {
      window.alert(
        "Sorry, this session cannot be deleted as there has already been bookings for it. Please disable it instead."
      );
      return;
    }

    const updatedDateInstances = input.value.filter(
      activityDateInstance => activityDateInstance._id !== id
    );

    input.onChange(updatedDateInstances);
  };

  const onEditSession = (id: string): void => {
    setEditingSessionId(id);
    setIsAddEditSessionModalOpen(true);
  };

  return clientQueryInfo.data ? (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {formState.dirtyFields["datesInstances"] && !isNewActivityGroup && (
        <div
          className="mb-6 space-y-4"
          data-cy="msg-changes-on-save-activity-sessions"
        >
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <div className="text-sm text-blue-700">
                  <p>Changes will be applied on save.</p>
                </div>
              </div>
            </div>
          </div>

          {hasAllSessionsOrSubscriptionTicketSales && (
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-yellow-700">
                    <p>
                      Please note that any changes to sessions made here will
                      affect existing purchases of &apos;All sessions&apos; or
                      Subscription tickets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Lets the browser know where to jump to if there's a validation error */}
      <input
        type="text"
        name={input.name}
        style={{ border: 0, padding: 0, width: 0, height: 0, display: "block" }}
      />

      {touched && error && (
        <>
          <div className="mb-5 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p
                  className="text-sm text-red-700"
                  data-cy={`error-${kebabCase(label)}`}
                >
                  {error}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {input.value.length > 0 && (
        <TableWrapper shadow={false}>
          <table
            className="min-w-full divide-y divide-gray-200"
            data-cy="table-activity-sessions"
          >
            <thead data-cy="table-head">
              <tr data-cy="table-head-row">
                <th
                  className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500"
                  data-cy="table-head-cell"
                >
                  Date
                </th>
                <th
                  className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500"
                  data-cy="table-head-cell"
                >
                  Time
                </th>
                <th
                  className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500"
                  data-cy="table-head-cell"
                >
                  Place limit
                </th>
                <th
                  className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500"
                  data-cy="table-head-cell"
                >
                  Sales
                </th>
                <th
                  className="bg-gray-50 px-6 py-3"
                  data-cy="table-head-cell"
                ></th>
              </tr>
            </thead>
            <tbody>
              {input.value
                .sort((a, b) =>
                  compareAsc(new Date(a.date.start), new Date(b.date.start))
                )
                .map((activity, index) => (
                  <tr className="bg-white" key={index} data-cy="table-row">
                    <td
                      className={cn(
                        "whitespace-no-wrap px-6 py-4 text-sm font-medium text-gray-900",
                        !activity.enabled && "text-opacity-50"
                      )}
                      data-cy="table-cell"
                    >
                      {activity.date &&
                        formatDate(
                          activity.date?.start,
                          "E d MMM yyyy",
                          clientQueryInfo.data.timeZone
                        )}
                    </td>
                    <td
                      className={cn(
                        "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                        !activity.enabled && "text-opacity-50"
                      )}
                      data-cy="table-cell"
                    >
                      {activity.date &&
                        formatDate(
                          activity.date.start,
                          "h.mma",
                          clientQueryInfo.data.timeZone
                        ).toLowerCase()}
                      {activity.date &&
                        ` - ${formatDate(
                          activity.date.end,
                          "h.mma",
                          clientQueryInfo.data.timeZone
                        ).toLowerCase()}`}{" "}
                    </td>
                    <td
                      className={cn(
                        "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                        !activity.enabled && "text-opacity-50"
                      )}
                      data-cy="table-cell"
                    >
                      {activity.placeLimit || "N/A"}
                    </td>
                    <td
                      className={cn(
                        "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                        !activity.enabled && "text-opacity-50"
                      )}
                      data-cy="table-cell"
                    >
                      {activityGroupSales?.activitySales[activity._id]}
                    </td>
                    <td
                      className={cn(
                        "whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium",
                        !activity.enabled && "text-opacity-50"
                      )}
                      data-cy="table-cell"
                    >
                      <a
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={(): void => onEditSession(activity._id)}
                      >
                        Edit
                      </a>
                      {" | "}
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={(): void =>
                          onUpdateSessionStatus(activity._id)
                        }
                      >
                        {activity.enabled ? "Disable" : "Enable"}
                      </a>
                      {" | "}
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={(): void => onDeleteSession(activity._id)}
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      <div className={cn(input.value.length > 0 && "mt-4 flex space-x-3")}>
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setIsAddEditSessionModalOpen(true)}
          data-cy="btn-add-session"
        >
          <IconPlusCircle className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add new session
        </button>
        {input.value.length > 1 && (
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => setIsBulkUpdatePlaceLimitsModalOpen(true)}
            data-cy="btn-bulk-update-place-limits"
          >
            Bulk update place limits
          </button>
        )}
      </div>

      <ActivitySessionsAddEditModal
        isOpen={isAddEditSessionModalOpen}
        editingSessionId={editingSessionId}
        client={clientQueryInfo.data}
        setIsOpen={setIsAddEditSessionModalOpen}
        onCancel={onCancelAddEditSession}
        onConfirmSessionAddEdit={onConfirmSessionAddEdit}
      />

      <ActivitySessionsBulkUpdatePlaceLimitsModal
        isOpen={isBulkUpdatePlaceLimitsModalOpen}
        setIsOpen={setIsBulkUpdatePlaceLimitsModalOpen}
      />
    </div>
  ) : null;
};

export default ActivityDateInstances;
