import { useState } from "react";
import { cn } from "utils/cn";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import TableWrapper from "components/admin/table/TableWrapper";
import useClient from "hooks/useClient";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import IconInformationCircleSolid from "public/images/icons/information-circle_solid.svg";
import type {
  CancellationPolicyRuleFormData,
  CancellationPolicyRule} from "types/model/cancellation";
import {
  RefundMethod
} from "types/model/cancellation";
import { getCancellationWindowReadableValue } from "helpers/cancellations";
import CancellationRuleConfig from "components/admin/CancellationRuleConfig";
import CancellationPolicyRulesAddEditModal from "components/admin/CancellationPolicyRulesAddEditModal";
import { DateDuration } from "types/date";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { convertAmountBasedOnCurrency } from "helpers/currency";

interface CancellationPolicyRuleItemProps
  extends UseFieldApiProps<CancellationPolicyRule[], HTMLElement> {
  isNew?: boolean;
  hasStripePaymentsEnabled?: boolean;
}

const CancellationPolicyRules = (
  props: UseFieldApiConfig
): React.ReactElement => {
  const {
    isNew,
    input,
    disabled,
    hasStripePaymentsEnabled,
    meta: { error, touched }
  }: CancellationPolicyRuleItemProps = useFieldApi(props);
  const [isDirty, setIsDirty] = useState(false);

  const [editingItemId, setEditingItemId] = useState<string>(null);
  const [isAddEditItemModalOpen, setIsAddEditItemModalOpen] =
    useState<boolean>(false);

  const clientQueryInfo = useClient();

  const handleAddNewItem = (): void => {
    setIsAddEditItemModalOpen(true);
  };

  const onConfirmItemAddEdit = (
    formData: CancellationPolicyRuleFormData,
    editingItemId = null
  ): void => {
    if (editingItemId) {
      const editingItemIndex = input.value.findIndex(
        item => item._id === editingItemId
      );
      const updatedItems = [...input.value];
      updatedItems[editingItemIndex] = {
        ...updatedItems[editingItemIndex],
        cancellationWindow: {
          unit: formData.cancellationWindow.unit || DateDuration.Hours,
          amount: parseInt(formData.cancellationWindow.amount, 10)
        },
        config: {
          ...updatedItems[editingItemIndex].config,
          refundMethods: formData.refundMethods,
          ...(formData.refundMethods.includes(RefundMethod.AccountCredit) &&
          formData.accountCreditExpiry
            ? {
                accountCreditExpiry: {
                  unit: formData.accountCreditExpiry.unit || DateDuration.Days,
                  amount: parseInt(formData.accountCreditExpiry.amount, 10)
                }
              }
            : {
                accountCreditExpiry: null
              }),
          percentageToRefund: Math.round(
            parseFloat(formData.percentageToRefund)
          ),
          ...(formData.fee && {
            fee: convertAmountBasedOnCurrency(
              formData.fee,
              clientQueryInfo.data.currency
            )
          })
        }
      };

      input.onChange(updatedItems);
    } else {
      const newItem: CancellationPolicyRule = {
        _id: `temp_${Math.random().toString(36).substring(7)}`,
        cancellationWindow: {
          unit: formData.cancellationWindow.unit || DateDuration.Hours,
          amount: parseInt(formData.cancellationWindow.amount, 10)
        },
        config: {
          refundMethods: formData.refundMethods,
          ...(formData.refundMethods.includes(RefundMethod.AccountCredit) &&
            formData.accountCreditExpiry && {
              accountCreditExpiry: {
                unit: formData.accountCreditExpiry.unit || DateDuration.Days,
                amount: parseInt(formData.accountCreditExpiry.amount, 10)
              }
            }),
          percentageToRefund: Math.round(
            parseFloat(formData.percentageToRefund)
          ),
          ...(formData.fee && {
            fee: convertAmountBasedOnCurrency(
              formData.fee,
              clientQueryInfo.data.currency
            )
          })
        }
      };

      const updatedItems = [...input.value, newItem];
      input.onChange(updatedItems);
    }

    setEditingItemId(null);
    setIsDirty(true);
  };

  const onEditItem = (id: string): void => {
    setEditingItemId(id);
    setIsAddEditItemModalOpen(true);
  };

  const onCancelAddEditItem = () => {
    setEditingItemId(null);
  };

  const onDeleteItem = (id: string): void => {
    const updatedItems = input.value.filter(item => item._id !== id);

    input.onChange(updatedItems);
    setIsDirty(true);
  };

  return clientQueryInfo.data ? (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {touched && error ? (
        <>
          <div className="mb-5 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700" data-cy="error-rules">
                  {error}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : isDirty && !isNew ? (
        <div className="mb-5 rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <IconInformationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Changes will be applied on save.
              </p>
            </div>
          </div>
        </div>
      ) : null}
      {input.value.length > 0 && (
        <TableWrapper shadow={false}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Cancellation window
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Configuration
                </th>
                <th className="bg-gray-50 px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {input.value.map((item, index) => (
                <tr className="bg-white" key={index}>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm",
                      index !== input.value.length - 1 && "border-b"
                    )}
                  >
                    <span className="font-medium text-gray-900">
                      More than{" "}
                      {getCancellationWindowReadableValue(
                        item.cancellationWindow
                      )}
                    </span>
                    <br />
                    <span className="text-gray-500">
                      before session start time
                    </span>
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      index !== input.value.length - 1 && "border-b"
                    )}
                  >
                    <CancellationRuleConfig
                      cancellationRule={item}
                      client={clientQueryInfo.data}
                    />
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium",
                      index !== input.value.length - 1 && "border-b"
                    )}
                  >
                    <a
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={(): void => !disabled && onEditItem(item._id)}
                    >
                      Edit
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={(): void => !disabled && onDeleteItem(item._id)}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      {!disabled && (
        <div className={cn(input.value.length > 0 && "mt-4")}>
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleAddNewItem}
            >
              <IconPlusCircle
                width={24}
                height={24}
                className="-ml-1 mr-2 h-5 w-5"
              />
              Add new rule
            </button>
          </span>
        </div>
      )}

      <CancellationPolicyRulesAddEditModal
        isOpen={isAddEditItemModalOpen}
        client={clientQueryInfo.data}
        editingItemId={editingItemId}
        hasStripePaymentsEnabled={hasStripePaymentsEnabled}
        setIsOpen={setIsAddEditItemModalOpen}
        onCancel={onCancelAddEditItem}
        onConfirmItemAddEdit={onConfirmItemAddEdit}
      />
    </div>
  ) : null;
};

export default CancellationPolicyRules;
