import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { getShouldNotRetry } from "helpers/auth";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { ActivityGroupSalesForAttendeeData } from "types/model/activity-group";
import { handleRequestError } from "utils/handleRequestError";

const getActivityGroupSalesForAttendee = async (
  _: string,
  userId: string,
  activityGroupIds: string[]
): Promise<ActivityGroupSalesForAttendeeData> => {
  const response: AxiosResponse<ActivityGroupSalesForAttendeeData> =
    await axios.get(
      `/api/bookings/attendee-sales?userId=${userId}&activityGroupIds=${JSON.stringify(
        activityGroupIds
      )}`
    );

  return response.data;
};

export default function useActivityGroupSalesForAttendee(
  userId: string,
  activityGroupIds: string[]
): QueryResult<ActivityGroupSalesForAttendeeData, AxiosError<ErrorResponse>> {
  return useQuery(
    ["activityGroupSalesForAttendee", userId, activityGroupIds],
    getActivityGroupSalesForAttendee,
    {
      retry: (failureCount: number, error) => {
        return getShouldNotRetry(error.response?.status)
          ? false
          : failureCount < 3;
      },
      onError: handleRequestError,
      enabled: !!userId
    }
  );
}
