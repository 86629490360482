import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import FieldValue from "components/FieldValue";
import useClient from "hooks/useClient";
import useCurrentUser from "hooks/useCurrentUser";
import { cn } from "utils/cn";

interface AttendeeDetailsProps {
  name: string;
}

const AttendeeDetails = ({
  name
}: AttendeeDetailsProps): React.ReactElement => {
  const { getFieldState } = useFormApi();

  const attendeeIdFieldName = name.replace("attendeeDetails", "attendeeId");
  const attendeeId = getFieldState(attendeeIdFieldName)?.value;

  const currentUserInfo = useCurrentUser();
  const useClientInfo = useClient();

  const attendee = currentUserInfo.data?.attendees.find(
    attendee => attendee._id === attendeeId
  );

  return (
    <div className="mt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
      <div className="sm:col-span-2 sm:col-start-2 sm:max-w-sm">
        {!attendee || useClientInfo.isLoading ? (
          <div className="my-4">
            <div className="animate-pulse">
              <div className="mb-2 h-4 w-5/6 rounded bg-gray-300"></div>
              <div className="mb-1 h-3 w-2/3 rounded bg-gray-300"></div>
              <div className="h-3 w-2/3 rounded bg-gray-300"></div>
            </div>
          </div>
        ) : attendee && useClientInfo.data ? (
          <ul className="grid grid-cols-1 gap-4">
            {attendee.fieldData
              .filter(item => item.field)
              .sort((a, b) => (a.field.weight > b.field.weight ? 1 : -1))
              .map(item => (
                <div
                  key={item.field._id}
                  className={cn("sm:grid sm:grid-cols-3 sm:gap-4 sm:px-1")}
                >
                  <dd className="text-sm font-medium text-gray-500">
                    {item.field.title}
                  </dd>
                  <dt className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <FieldValue
                      fieldDataItem={item}
                      client={useClientInfo.data}
                    />
                  </dt>
                </div>
              ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default AttendeeDetails;
