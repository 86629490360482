export const KlarnaIcon = () => {
  return (
    <div className="grid h-5 w-[31px] place-content-center rounded-[2px] border border-[#F7AEC3] bg-[#F7AEC3]">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        role="img"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-5 text-black"
      >
        <path d="M4.592 2v20H0V2h4.592zm11.46 0c0 4.194-1.583 8.105-4.415 11.068l-.278.283L17.702 22h-5.668l-6.893-9.4 1.779-1.332c2.858-2.14 4.535-5.378 4.637-8.924L11.562 2h4.49zM21.5 17a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"></path>
      </svg>
    </div>
  );
};
