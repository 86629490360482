import { useState } from "react";
import { cn } from "utils/cn";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import TableWrapper from "components/admin/table/TableWrapper";
import useClient from "hooks/useClient";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import IconInformationCircleSolid from "public/images/icons/information-circle_solid.svg";
import type {
  MultiActivityCondition
} from "types/model/discount-rule";
import {
  DiscountRuleUnit
} from "types/model/discount-rule";
import type {
  MultiActivityDiscountRuleItemFormData
} from "components/admin/MultiActivityDiscountRuleConditionsAddEditModal";
import MultiActivityDiscountRuleConditionsAddEditModal from "components/admin/MultiActivityDiscountRuleConditionsAddEditModal";
import { formatCurrency } from "helpers/helpers";
import type { FormTemplateType } from "types/form";
import { convertAmountBasedOnCurrency } from "helpers/currency";

interface MultiActivityDiscountRuleConditionsProps
  extends UseFieldApiProps<MultiActivityCondition[], HTMLElement> {
  isNewDiscountRule?: boolean;
  inModal?: boolean;
  formTemplate?: FormTemplateType;
}

const MultiActivityDiscountRuleConditions = (
  props: UseFieldApiConfig
): React.ReactElement => {
  const {
    isNewDiscountRule,
    input,
    disabled
  }: MultiActivityDiscountRuleConditionsProps = useFieldApi(props);
  const [isDirty, setIsDirty] = useState(false);

  const [editingItemId, setEditingItemId] = useState<string>(null);
  const [isAddEditItemModalOpen, setIsAddEditItemModalOpen] =
    useState<boolean>(false);

  const clientQueryInfo = useClient();

  const handleAddNewItem = (): void => {
    setIsAddEditItemModalOpen(true);
  };

  const onConfirmItemAddEdit = (
    formData: MultiActivityDiscountRuleItemFormData,
    editingItemId = null
  ): void => {
    if (editingItemId) {
      const editingItemIndex = input.value.findIndex(
        item => item._id === editingItemId
      );
      const updatedItems = [...input.value];
      updatedItems[editingItemIndex] = {
        ...updatedItems[editingItemIndex],
        minimumActivities: parseInt(formData.minimumActivities, 10),
        unit: formData.unit,
        ...(formData.unit === DiscountRuleUnit.FixedAmount && {
          fixedAmount: convertAmountBasedOnCurrency(
            formData.fixedAmount,
            clientQueryInfo.data.currency
          )
        }),
        ...(formData.unit === DiscountRuleUnit.Percentage && {
          percentage: formData.percentage
        })
      };

      input.onChange(updatedItems);
    } else {
      const newItem: MultiActivityCondition = {
        _id: `temp_${Math.random().toString(36).substring(7)}`,
        minimumActivities: parseInt(formData.minimumActivities, 10),
        unit: formData.unit,
        ...(formData.unit === DiscountRuleUnit.FixedAmount && {
          fixedAmount: convertAmountBasedOnCurrency(
            formData.fixedAmount,
            clientQueryInfo.data.currency
          )
        }),
        ...(formData.unit === DiscountRuleUnit.Percentage && {
          percentage: formData.percentage
        })
      };

      const updatedItems = [...input.value, newItem];
      input.onChange(updatedItems);
    }

    setEditingItemId(null);
    setIsDirty(true);
  };

  const onEditItem = (id: string): void => {
    setEditingItemId(id);
    setIsAddEditItemModalOpen(true);
  };

  const onCancelAddEditItem = () => {
    setEditingItemId(null);
  };

  const onDeleteItem = (id: string): void => {
    const updatedItems = input.value.filter(item => item._id !== id);

    input.onChange(updatedItems);
    setIsDirty(true);
  };

  return clientQueryInfo.data ? (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {isDirty && !isNewDiscountRule && (
        <div className="mb-5 rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <IconInformationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Changes will be applied on save.
              </p>
            </div>
          </div>
        </div>
      )}
      {input.value.length > 0 && (
        <TableWrapper shadow={false}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Condition
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Discount
                </th>
                <th className="bg-gray-50 px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {input.value
                .sort((a, b) =>
                  a.minimumActivities > b.minimumActivities ? 1 : -1
                )
                .map((item, index) => (
                  <tr className="bg-white" key={index}>
                    <td className="whitespace-no-wrap px-6 py-4 text-sm font-medium text-gray-900">
                      {item.minimumActivities} or more activities booked
                    </td>
                    <td className="whitespace-no-wrap px-6 py-4 text-sm text-gray-500">
                      {item.unit === DiscountRuleUnit.FixedAmount && (
                        <>
                          {formatCurrency({
                            rawAmount: item.fixedAmount,
                            currency: clientQueryInfo.data.currency
                          })}
                        </>
                      )}
                      {item.unit === DiscountRuleUnit.Percentage && (
                        <>{item.percentage}%</>
                      )}
                    </td>
                    <td className="whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium">
                      <a
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={(): void => !disabled && onEditItem(item._id)}
                      >
                        Edit
                      </a>
                      {" | "}
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={(): void =>
                          !disabled && onDeleteItem(item._id)
                        }
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      {!disabled && (
        <div className={cn(input.value.length > 0 && "mt-4")}>
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleAddNewItem}
            >
              <IconPlusCircle
                width={24}
                height={24}
                className="-ml-1 mr-2 h-5 w-5"
              />
              Add new condition
            </button>
          </span>
        </div>
      )}

      <MultiActivityDiscountRuleConditionsAddEditModal
        isOpen={isAddEditItemModalOpen}
        client={clientQueryInfo.data}
        editingItemId={editingItemId}
        setIsOpen={setIsAddEditItemModalOpen}
        onCancel={onCancelAddEditItem}
        onConfirmItemAddEdit={onConfirmItemAddEdit}
      />
    </div>
  ) : null;
};

export default MultiActivityDiscountRuleConditions;
