import { Alert } from "components/Alert";
import { useCheckAgeEligibility } from "features/attendees/hooks/useCheckAgeEligibility";
import type { Activity } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Attendee } from "types/model/attendee";

type Props = {
  userAttendees: Attendee[];
  currentAttendee: string;
  activityGroup: ActivityGroup;
  sessions: Activity<string>[];
};

/**
 * Displays an alert if the attendee is outside the age range for the activity.
 */
export const AttendeeAgeWarning = ({
  userAttendees,
  currentAttendee,
  activityGroup,
  sessions
}: Props) => {
  const { restrictions, hasBlock } = useCheckAgeEligibility({
    userAttendees,
    currentAttendee,
    activityGroup,
    sessions
  });

  if (!restrictions?.isAttendeeDobOutsideAgeRestriction) return null;

  return (
    <Alert className="mt-1">
      {hasBlock
        ? "Sorry the attendee is outside the age range for this activity and cannot be added to the waitlist."
        : "Please note that the attendee is outside of the age range for this activity."}
    </Alert>
  );
};
