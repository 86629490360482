import { cn } from "utils/cn";

interface BadgeProps {
  color?: string;
  children?: React.ReactNode;
  className?: string;
  borderRadius?: "medium" | "full";
  cy?: string;
}

const colors = {
  gray: "bg-gray-100 text-gray-800",
  red: "bg-red-100 text-red-800",
  yellow: "bg-yellow-100 text-yellow-800",
  green: "bg-green-100 text-green-800",
  blue: "bg-blue-100 text-blue-800",
  indigo: "bg-indigo-100 text-indigo-800",
  purple: "bg-purple-100 text-purple-800",
  pink: "bg-pink-100 text-pink-800"
};

const Badge = ({
  children,
  color = "gray",
  className,
  borderRadius = "full",
  cy
}: BadgeProps): React.ReactElement => (
  <span
    {...(cy && { "data-cy": cy })}
    className={cn(
      "inline-flex items-center px-2.5 py-0.5 text-xs font-medium",
      borderRadius === "medium" ? "rounded-md" : "rounded-full",
      color && colors?.[color as keyof typeof colors],
      className
    )}
  >
    {children}
  </span>
);

export default Badge;
