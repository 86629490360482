import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import Badge from "components/Badge";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from "components/ui/select";
import { getFieldTypeReadableName } from "helpers/field";
import { kebabCase } from "lodash";
import {
  CalendarDays,
  Check,
  ChevronDown,
  ListChecks,
  Tally4,
  Text,
  Upload
} from "lucide-react";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import { FormTemplateType } from "types/form";
import type { Client } from "types/model/client";
import { FieldType } from "types/model/field";
import { cn } from "utils/cn";

type SelectProps = UseFieldApiConfig & {
  arrayField?: boolean;
  client?: Client;
  emptyOptionLabel?: string;
  formTemplate?: FormTemplateType;
  helpText?: string;
  index?: number;
  inModal?: boolean;
  isAdminOnly?: boolean;
  isRequired?: boolean;
  isSubFormSubmitted?: boolean;
  label: string;
  showFile?: boolean;
};

/**
 * Select component that renders a select dropdown. Used in data-driven forms.
 */
export default function SelectFieldType(props: SelectProps) {
  const {
    arrayField,
    emptyOptionLabel,
    formTemplate = FormTemplateType.Default,
    index,
    inModal,
    input,
    isAdminOnly,
    isRequired,
    isSubFormSubmitted,
    label,
    meta: { error, touched },
    showFile
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  const fields = {
    [FieldType.Text]: {
      value: FieldType.Text,
      label: "Short text",
      icon: <Text className="h-4 w-4 text-zinc-600" />
    },

    [FieldType.Textarea]: {
      value: FieldType.Textarea,
      label: "Long text",
      icon: <Tally4 className="h-4 w-4 rotate-90 text-zinc-600" />
    },
    [FieldType.SelectList]: {
      value: FieldType.SelectList,
      label: "Dropdown",
      icon: <ChevronDown className="h-5 w-5 text-zinc-600" />
    },
    [FieldType.CheckboxSingle]: {
      value: FieldType.CheckboxSingle,
      label: "Single checkbox",
      icon: <Check className="h-4 w-4 text-zinc-600" />
    },
    [FieldType.CheckboxMultiple]: {
      value: FieldType.CheckboxMultiple,
      label: "Select multiple",
      icon: <ListChecks className="h-4 w-4 text-zinc-600" />
    },
    [FieldType.Date]: {
      value: FieldType.Date,
      label: getFieldTypeReadableName(FieldType.Date),
      icon: <CalendarDays className="h-4 w-4 text-zinc-600" />
    },
    [FieldType.FileUpload]: {
      value: FieldType.FileUpload,
      label: getFieldTypeReadableName(FieldType.FileUpload),
      icon: <Upload className="h-4 w-4 text-zinc-600" />
    }
  };

  const options = [
    {
      title: "Text inputs",
      options: [fields[FieldType.Text], fields[FieldType.Textarea]]
    },
    {
      title: "Select inputs",
      options: [
        fields[FieldType.SelectList],
        fields[FieldType.CheckboxSingle],
        fields[FieldType.CheckboxMultiple]
      ]
    },
    {
      title: "Misc. inputs",
      options: [
        fields[FieldType.Date],
        ...(showFile ? [fields[FieldType.FileUpload]] : [])
      ]
    }
  ];

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label
        className={cn(
          "text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
          !isAdminOnly && "block",
          isAdminOnly && "flex flex-wrap gap-2"
        )}
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
        {isAdminOnly && <Badge>Admin only</Badge>}
      </label>
      <div className={cn(isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm")}>
        <div className="relative">
          <Select
            onValueChange={input.onChange}
            name={input.name}
            value={input.value}
          >
            <SelectTrigger
              className={cn(
                "mt-1 flex w-full items-center rounded-md border border-gray-300 py-2 pl-3 pr-2 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
                touchedOrSubFormSubmitted &&
                  error &&
                  "focus:shadow-outline-red border-red-300 text-red-900 placeholder-red-300 focus:border-red-300"
              )}
            >
              <SelectValue>
                {fields[input.value as keyof typeof fields]?.label ||
                  emptyOptionLabel}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {options.map(({ title, options }, i) => {
                return (
                  <SelectGroup key={i} className={cn(i !== 0 && "mt-2")}>
                    <SelectLabel className="px-2 py-1 text-xs font-medium text-zinc-400">
                      {title}
                    </SelectLabel>
                    {options.map(({ value, label, icon }, i) => {
                      return (
                        <SelectItem
                          key={i}
                          value={value}
                          className="px-2 py-1"
                          hideCheck
                        >
                          <span className="flex flex-row items-center gap-2">
                            <span className="grid h-6 w-6 place-content-center rounded-sm bg-zinc-100">
                              {icon}
                            </span>
                            <span>{label}</span>
                          </span>
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>
                );
              })}
            </SelectContent>
          </Select>
          {touchedOrSubFormSubmitted && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 bg-white text-red-500"
              />
            </div>
          )}
        </div>
        {touchedOrSubFormSubmitted && error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
}
