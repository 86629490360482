import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { HelpText } from "components/form/HelpText";
import BookingAgreementPolicyModal from "components/site/BookingAgreementPolicyModal";
import { kebabCase } from "lodash";
import React, { useState } from "react";
import { FormTemplateType } from "types/form";
import type { Agreement as TAgreement } from "types/model/booking-agreement";
import { cn } from "utils/cn";

type Props = UseFieldApiProps<string, HTMLElement> & {
  id?: string;
  agreement?: TAgreement;
};

export const Agreement = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    isDisabled,
    meta: { error, touched },
    index,
    helpText,
    inModal,
    arrayField,
    agreement,
    formTemplate = FormTemplateType.Default
  }: Props = useFieldApi(props);

  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getBookingAgreementText = (bookingAgreement: TAgreement) => {
    const policyLinks = bookingAgreement.text.match(/<link>(.*?)<\/link>/gi);
    if (!policyLinks || !bookingAgreement.linkToPolicy) {
      return bookingAgreement.text;
    }

    const policyLinksText = policyLinks.map(val =>
      val.replace(/<\/?link>/gi, "")
    );
    const textParts = bookingAgreement.text.split(/<link>[\s\S]*?<\/link>/gi);

    return (
      <>
        {textParts[0]}
        {policyLinksText.map((policyLinkText, index) => (
          <React.Fragment key={index}>
            <a
              className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"
              onClick={() => setIsModalOpen(true)}
            >
              {policyLinkText}
            </a>
            {textParts[index + 1]}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      {isSeamlessFormTemplate ? (
        <div
          className={cn("relative flex items-start", !isFirstItem && "mt-7")}
          data-cy={`form-field-${kebabCase(label)}`}
        >
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id={input.name}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                {...input}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={input.name} className="font-medium text-gray-700">
                {label}
              </label>
              <p className="text-gray-500">
                {getBookingAgreementText(agreement)}
              </p>
              {helpText && <HelpText label={label} helpText={helpText} />}
              {touched && error && (
                <p className="mt-2 text-sm text-red-600">{error}</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={cn(
            !inModal && "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
            !arrayField && index !== 0 && "mt-5 border-t border-gray-200 pt-5",
            arrayField && index !== 0 && "mt-5"
          )}
          data-cy={`form-field-${kebabCase(label)}`}
        >
          <label
            className="hidden text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:block sm:pt-2"
            htmlFor={input.name}
          >
            {label}
            {isRequired && "*"}
          </label>
          <div className="relative mt-1 flex items-start sm:col-span-2 sm:max-w-sm">
            <div className="absolute flex h-5 items-center">
              <input
                id={input.name}
                type="checkbox"
                className={cn(
                  "form-checkbox h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500",
                  isDisabled && "opacity-50"
                )}
                disabled={isDisabled}
                data-cy={`input-${kebabCase(label)}`}
                {...input}
              />
            </div>
            <div className="pl-7 text-sm">
              <label htmlFor={input.name} className="font-medium text-gray-700">
                {getBookingAgreementText(agreement)}
              </label>
              {helpText && <HelpText label={label} helpText={helpText} />}
              {touched && error && (
                <p className="mt-2 text-sm text-red-600">{error}</p>
              )}
            </div>
          </div>
        </div>
      )}
      <BookingAgreementPolicyModal
        bookingAgreement={agreement}
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
      />
    </>
  );
};
