import { useState } from "react";
import { kebabCase } from "lodash";
import { cn } from "utils/cn";
import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import TableWrapper from "components/admin/table/TableWrapper";
import useClient from "hooks/useClient";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import IconInformationCircleSolid from "public/images/icons/information-circle_solid.svg";
import CcvProviderAddEdit from "components/admin/CcvProviderAddEdit";
import { XCircleIcon } from "@heroicons/react/20/solid";
import type { CcvProvider } from "types/model/payment";
import type { IdsHash } from "types/general";

interface CCVProvidersProps
  extends UseFieldApiProps<CcvProvider[], HTMLElement> {
  ccvProvidersUsedCheck?: IdsHash;
}

const CCVProviders = (props: UseFieldApiConfig): React.ReactElement => {
  const {
    label,
    ccvProvidersUsedCheck,
    meta: { error, touched },
    input
  }: CCVProvidersProps = useFieldApi(props);
  const [isDirty, setIsDirty] = useState(false);
  const [isAddingNewCcvProvider, setIsAddingNewCcvProvider] = useState(false);
  const [editingCcvProviderId, setEditingCcvProviderId] =
    useState<string>(null);
  const [newOrEditingCcvProvider, setNewOrEditingCcvProvider] =
    useState<CcvProvider>(null);

  const clientQueryInfo = useClient();

  const handleAddNewCcvProvider = (): void => {
    setIsAddingNewCcvProvider(true);
  };

  const onConfirmCcvProviderAdd = (): void => {
    const ccvProvider: Partial<CcvProvider> = {
      _id: `temp_${Math.random().toString(36).substring(7)}`,
      name: newOrEditingCcvProvider.name,
      paymentInstructions: newOrEditingCcvProvider.paymentInstructions,
      enabled: true
    };

    const updatedCcvProviders = [...input.value, ccvProvider];

    input.onChange(updatedCcvProviders);
    setIsDirty(true);

    setIsAddingNewCcvProvider(false);
    setNewOrEditingCcvProvider(null);
  };

  const onEditCcvProvider = (id: string): void => {
    const editingCcvProvider = input.value.find(
      ccvProvider => ccvProvider._id === id
    );
    setNewOrEditingCcvProvider(editingCcvProvider);
    if (editingCcvProvider) {
      setEditingCcvProviderId(editingCcvProvider._id);
    }
  };

  const onConfirmCcvProviderEdit = (): void => {
    const editingCcvProviderIndex = input.value.findIndex(
      ccvProvider => ccvProvider._id === editingCcvProviderId
    );

    const updatedCcvProviderValues = [...input.value];

    updatedCcvProviderValues[editingCcvProviderIndex] = newOrEditingCcvProvider;

    input.onChange(updatedCcvProviderValues);
    setIsDirty(true);

    setEditingCcvProviderId(null);
    setNewOrEditingCcvProvider(null);
  };

  const onCancelCcvProviderAddEdit = (): void => {
    setIsAddingNewCcvProvider(false);
    setEditingCcvProviderId(null);
    setNewOrEditingCcvProvider(null);
  };

  const onUpdateCcvProviderStatus = (id: string): void => {
    const ccvProviderToUpdateStatusIndex = input.value.findIndex(
      ccvProvider => ccvProvider._id === id
    );

    const updatedCcvProviderValues = [...input.value];

    updatedCcvProviderValues[ccvProviderToUpdateStatusIndex].enabled =
      !updatedCcvProviderValues[ccvProviderToUpdateStatusIndex].enabled;

    input.onChange(updatedCcvProviderValues);
    setIsDirty(true);
  };

  const onDeleteCcvProvider = (id: string): void => {
    if (ccvProvidersUsedCheck[id]) {
      window.alert(
        "Sorry, this Childcare Voucher Provider cannot be deleted as there's already been a payment transaction using it. Please disable it instead."
      );
      return;
    }
    const updatedCcvProviderValues = input.value.filter(
      ccvProvider => ccvProvider._id !== id
    );
    input.onChange(updatedCcvProviderValues);
    setIsDirty(true);
  };

  return clientQueryInfo.data ? (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {isDirty && (
        <div className="mb-5 rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <IconInformationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Changes will be applied on save.
              </p>
            </div>
          </div>
        </div>
      )}

      {touched && error && (
        <>
          <div className="mb-5 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p
                  className="text-sm text-red-700"
                  data-cy={`error-${kebabCase(label)}`}
                >
                  {error}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {input.value.length > 0 && (
        <TableWrapper shadow={false}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Name
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Payment instructions
                </th>
                <th className="bg-gray-50 px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {input.value.map((ccvProvider, index) => (
                <tr className="bg-white" key={index}>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm font-medium text-gray-900",
                      !ccvProvider.enabled && "text-opacity-50"
                    )}
                  >
                    {ccvProvider.name}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      !ccvProvider.enabled && "text-opacity-50"
                    )}
                  >
                    {ccvProvider.paymentInstructions}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium",
                      !ccvProvider.enabled && "text-opacity-50"
                    )}
                  >
                    <a
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={(): void => onEditCcvProvider(ccvProvider._id)}
                    >
                      Edit
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={(): void =>
                        onUpdateCcvProviderStatus(ccvProvider._id)
                      }
                    >
                      {ccvProvider.enabled ? "Disable" : "Enable"}
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={(): void => onDeleteCcvProvider(ccvProvider._id)}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      <div className={cn(input.value.length > 0 && "mt-4")}>
        <span className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleAddNewCcvProvider}
          >
            <IconPlusCircle
              width={24}
              height={24}
              className="-ml-1 mr-2 h-5 w-5"
            />
            Add new provider
          </button>
        </span>
      </div>

      <CcvProviderAddEdit
        ccvProvider={newOrEditingCcvProvider}
        setCcvProviderData={setNewOrEditingCcvProvider}
        isOpen={isAddingNewCcvProvider || Boolean(editingCcvProviderId)}
        onConfirm={
          isAddingNewCcvProvider
            ? onConfirmCcvProviderAdd
            : onConfirmCcvProviderEdit
        }
        onCancel={onCancelCcvProviderAddEdit}
      />
    </div>
  ) : null;
};

export default CCVProviders;
