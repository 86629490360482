import axios from "axios";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";
import { getErrorMessage } from "utils/getErrorMessage";

interface SaveAttendeeResponse {
  data: Attendee;
}

interface SaveAttendeeData {
  id: string;
  formData: FormDataItem[];
}

export default function useSaveAttendee(): MutationResultPair<
  SaveAttendeeResponse,
  ErrorResponse,
  SaveAttendeeData,
  unknown
> {
  return useMutation(
    async data => {
      try {
        return await axios.put(`/api/attendees/${data.id}`, data.formData);
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error => `Failed to save attendee: ${error.message}`,
            otherErrorMessage: "Failed to save attendee."
          })
        );
      }
    },
    {
      onSuccess: async response => {
        queryCache.setQueryData(["attendee", response.data._id], response.data);
        queryCache.invalidateQueries([
          "userAttendees",
          response?.data?.user?._id
        ]);
      }
    }
  );
}
