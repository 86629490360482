export enum HomeTabType {
  FieldOption = "fieldOption",
  Venue = "venue"
}

export interface HomeTab<F = string, FO = string, V = string> {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  path: string;
  type: HomeTabType;
  field: F;
  fieldOptions?: FO[];
  venues?: V[];
  weight: number;
  client: string;
  enabled: boolean;
}

export interface HomeTabFormData {
  _id?: string;
  name?: string;
  path?: string;
  field?: string;
  value?: string[];
  enabled?: true;
}
